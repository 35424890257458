import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "~/Store/Apis/BaseUrl";
// Components
import ResetPassowordForm from "./_ResetPassowordForm";
import ForgetPassowordSuccess from "./_ResetPassowordSuccess";
import "~/styles/_auth.scss";

class ForgetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errMsg: "",
      hasBackendError: false,
      backendErrorMsg: "",
      resetPasswordSuccess: false,
      form: {
        password: "",
        confirm_password: "",
      },
    };
    this.forgetPasswordToken = props.match.params.forgetPasswordToken;
  }

  componentDidMount() {
    document.title = "Oncoustics | Forget Password";
  }

  handleInputChange = (event) => {
    const form = { ...this.state.form };
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  submit = (e) => {
    e.preventDefault();
    const { password, confirm_password } = {
      ...this.state.form,
    };
    let errMsg = [];
    if (!password && password.length < 1) {
      errMsg.push("password");
    }

    if (!confirm_password && confirm_password.length < 1) {
      errMsg.push("confirm_password");
    }

    if (password !== confirm_password) {
      errMsg.push("confirm_password_not_match");
    }

    if (errMsg.length > 0) {
      this.setState({
        errMsg,
        hasError: true,
        hasBackendError: false,
        backendErrorMsg: "",
      });
    } else {
      this.resetPassword();
    }
  };

  resetPassword = () => {
    const { password } = this.state.form;
    axios
      .post(`${baseUrl}/auth/reset_password`, {
        forgetPasswordToken: this.forgetPasswordToken,
        password,
      })
      .then((response) => {
        let { success, message } = response.data;
        console.log(message);
        if (success) {
          this.setState({
            resetPasswordSuccess: true,
            hasBackendError: false,
            hasError: false,
            errMsg: "",
          });
        } else {
          this.setState({
            hasError: false,
            errMsg: [],
            hasBackendError: true,
            backendErrorMsg: message,
          });
        }
      })
      .catch(() => {
        this.setState({
          hasBackendError: true,
          backendErrorMsg: "Server Error",
        });
      });
  };

  render() {
    const {
      errMsg,
      hasBackendError,
      backendErrorMsg,
      resetPasswordSuccess,
    } = this.state;
    return (
      <section>
        <div className="row page">
          <div className="col-lg-3  col-md-12 left-sidebar">
            <div id="particles-js" />
          </div>
          <div className="col-md-12 top-sidebar"></div>
          <div className="col-md-1 col-md-0"></div>
          <div className="col-lg-7 col-md-9  main-section">
            <ul className="header-menu">
              <li>
                <Link to="/login">
                  <img
                    className="logo"
                    src="/images/logo_full.png"
                    alt="logo"
                  />
                </Link>
              </li>
              <li className="header-links">
                <p>
                  Don't have an account?{" "}
                  <Link to="/register" className="bold">
                    Sign Up
                  </Link>
                </p>
              </li>
            </ul>
            <div className="login-form">
              {resetPasswordSuccess ? (
                <ForgetPassowordSuccess />
              ) : (
                <ResetPassowordForm
                  errMsg={errMsg}
                  hasBackendError={hasBackendError}
                  backendErrorMsg={backendErrorMsg}
                  submit={this.submit}
                  handleInputChange={this.handleInputChange}
                />
              )}
            </div>
          </div>
          <div className="col-md-1 col-md-0"></div>
        </div>
      </section>
    );
  }
}

export default ForgetPasswordComponent;
