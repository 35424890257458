import React from "react";
import Select from "react-select";

const classificationOptions = [
  { value: "freeHand", label: "Hand Draw" },
  { value: "randomBox", label: "Random Box" },
];
export const AnswerSideBar = (props) => {
  let {
    showNextAnswer,
    handleInputChange,
    showDrawnTissue,
    completeImage,
    currentAnswer,
    answers,
    inputErrorMsg,
    currentAnswerIndex,
    preview_type,
  } = props;
  return (
    <div className="sidebar-actions">
      <div className="row">
        <div className="col-md-1 hide-on-desktop"></div>
        <div className="col-lg-12 col-md-5  col-sm-5">
          <div className="form-group">
            <label>Liver Grade</label>
            <input
              type="number"
              onChange={handleInputChange}
              name="liverGrade"
              defaultValue={currentAnswer.liverGrade}
              // value={form.liverGrade}
              className={`form-control search-input ${
                inputErrorMsg.liverGrade ? "red-label" : ""
              }`}
              placeholder="%"
            />
            {inputErrorMsg.liverGrade && (
              <span className="err-msg">{inputErrorMsg.liverGrade}</span>
            )}
          </div>
          <div className="form-group">
            <label>Minor Tissue Grade</label>
            <input
              type="number"
              onChange={handleInputChange}
              name="minorTissueGrade"
              defaultValue={currentAnswer.minorTissueGrade}
              // value={form.minorTissueGrade}
              className={`form-control search-input ${
                inputErrorMsg.minorTissueGrade ? "red-label" : ""
              }`}
              placeholder="%"
            />
            {inputErrorMsg.minorTissueGrade && (
              <span className="err-msg">{inputErrorMsg.minorTissueGrade}</span>
            )}
          </div>
          <div className="form-group">
            <label>Major Tissue Grade</label>
            <input
              type="number"
              onChange={handleInputChange}
              name="majorTissueGrade"
              defaultValue={currentAnswer.majorTissueGrade}
              // value={form.majorTissueGrade}
              className={`form-control search-input ${
                inputErrorMsg.majorTissueGrade ? "red-label" : ""
              }`}
              placeholder="%"
            />
            {inputErrorMsg.majorTissueGrade && (
              <span className="err-msg">{inputErrorMsg.majorTissueGrade}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12 col-md-5  col-sm-5">
          <div className="tissues-list">
            <h2> Submitted ROI</h2>
            <ul>
              {Object.keys(currentAnswer.tissues).length === 0 && (
                <li>
                  <p>Nothing Detected</p>
                </li>
              )}
              {Object.keys(currentAnswer.tissues).map((key, index) => (
                <li>
                  <p onClick={showDrawnTissue.bind(this, key)}>
                    {key.replace("_", " ")}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          {preview_type === "grading" &&
            currentAnswerIndex + 1 === answers.length && (
              <div className="classification">
                <Select
                  options={classificationOptions}
                  classNamePrefix="customSelect"
                  placeholder="Classification"
                />
              </div>
            )}
          {preview_type === "grading" && (
            <button className="btn btn-orange next-btn" onClick={completeImage}>
              Complete Image
            </button>
          )}

          {preview_type === "viewing" &&
            answers.length > currentAnswerIndex + 1 && (
              <button
                className="btn btn-orange next-btn"
                onClick={showNextAnswer.bind(this, currentAnswerIndex)}
              >
                Next Image
              </button>
            )}
        </div>
      </div>
    </div>
  );
};
