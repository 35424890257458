import React, { useEffect } from "react";

const StepFour = (props) => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 800) {
    return <StepFourMobile />;
  }
  return <StepFourDesktop />;
};

const StepFourMobile = (props) => {
  return (
    <div className="overlay-gif">
      <img src="images/gif/step4.gif" alt="GIF" />
    </div>
  );
};

const StepFourDesktop = (props) => {
  let styles = {};
  // Ipad
  var testTissueElement = document.getElementById("overlay-test-tissue");
  testTissueElement.style.cssText = `position:absolute;
      z-index:9999999999999999999;
      background-color:white;
      right:15%;
      width:100%;
      bottom:0%;
      `;
  testTissueElement.classList.remove("hide-this");

  let topPos = testTissueElement.getBoundingClientRect().top + window.scrollY;
  let leftPos = testTissueElement.getBoundingClientRect().left + window.scrollX;

  styles = {
    top: `${topPos + 70}px`,
    left: `${leftPos}px`,
  };

  // when unmounting the component
  useEffect(() => {
    return () => {
      testTissueElement.style.cssText = "";
      testTissueElement.classList.add("hide-this");
    };
  }, [testTissueElement]);
  return (
    <>
      <div className="overlay-steps">
        <div className="overlay-step-4">
          <div className="info" style={styles}>
            <i class="fas fa-arrow-up"></i>
            <p>
              You can review the drawing at any time by clicking on the tissue
              name, or delete the tissue by pressing delete
            </p>
            <i class="fas fa-arrow-down"></i>
          </div>
        </div>
      </div>
    </>
  );
};
export default StepFour;
