import React from "react";
import { Link } from "react-router-dom";

const InfoBoxComponent = (props) => {
  const { title, value, link } = props;
  return (
    <div className="stats-box">
      <p>{title}</p>
      <span> {value} </span>
      {link && (
        <Link to={link}>
          <button className="btn-orange"> View All</button>
        </Link>
      )}
    </div>
  );
};

export default InfoBoxComponent;
