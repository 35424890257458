import React from "react";
import { Link } from "react-router-dom";

export const TopBarComponent = (props) => {
  let {
    showPreviusAnswer,
    showNextAnswer,
    currentAnswerIndex,
    answers,
    history,
  } = props;
  return (
    <div className="row top-bar">
      <div className="col-md-1 mt-auto  " />
      <div className="col-md-9 mt-auto ">
        <h3>
          <Link to="#" className="no-style" onClick={() => history.goBack()}>
            <i class="fas fa-caret-left"></i> Back to Dashboard{" "}
          </Link>
        </h3>
      </div>
      <div className="col-lg-2 col-md-2 mt-auto  pl-4">
        <div className="right-info">
          <h2 className="counter">
            {currentAnswerIndex > 0 && (
              <i
                class="fas fa-caret-left"
                onClick={showPreviusAnswer.bind(this, currentAnswerIndex)}
              ></i>
            )}{" "}
            {currentAnswerIndex + 1}/{answers.length}{" "}
            {answers.length > currentAnswerIndex + 1 && (
              <i
                class="fas fa-caret-right"
                onClick={showNextAnswer.bind(this, currentAnswerIndex)}
              ></i>
            )}
          </h2>
        </div>
      </div>
    </div>
  );
};
