import React from "react";
import axios from "axios";
import store2 from "store2";
import numeral from "numeral";
// Components
import SidebarComponent from "~/Components/Sidebar";
import InfoBoxComponent from "~/Components/Boxes/InfoBoxComponent";
import ModuleStatusComponent from "~/Components/ModuleStatusComponent";
import TableLoaderComponent from "~/Components/Loaders/_TableLoaderComponent";
import { baseUrl } from "~/Store/Apis/BaseUrl";

// Scss
import "~/styles/_antd.css";
import "~/styles/_dashboard.scss";

const JWT_TOKEN = store2.get("token");

class Dashboard extends React.Component {
  state = {
    loading: true,
    hasError: false,
    error_msg: null,

    // Info
    user: null,
    type: null,
    caseId: null,
    total: null,
    answered: null,
    completedCases: null,
    totalTime: null,
    CaseRemainingQuestions: null,
    estimatedRemainingTime: null,
  };

  componentDidMount() {
    this.getDoctorsStats();
  }

  getDoctorsStats = () => {
    axios
      .get(`${baseUrl}/auth/dashboard`, {
        headers: {
          token: JWT_TOKEN,
        },
      })
      .then((res) => {
        console.log("respone is", res.data);
        const response = res.data;
        if (response.success === true) {
          let {
            type,
            caseId,
            total,
            answered,
            completedCases,
            totalTime,
            CaseRemainingQuestions,
            estimatedRemainingTime,
          } = response.info;
          this.setState({
            loading: false,
            user: response.user,
            type,
            caseId,
            total,
            answered,
            completedCases,
            totalTime,
            CaseRemainingQuestions,
            estimatedRemainingTime,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: response.message,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  render() {
    const {
      loading,
      user,
      type,
      caseId,
      total,
      answered,
      completedCases,
      totalTime,
      estimatedRemainingTime,
    } = this.state;

    return (
      <div className="dashboard">
        <SidebarComponent activeItem="exam" type="dr" />

        <section className="content">
          <div className="row page-title">
            <div className="col-md-1" />
            <div className="col-md-8">
              <h1 className="header">Welcome {user ? user.firstName : ""}!</h1>
            </div>
          </div>

          <div className="row stats">
            <div className="col-md-1" />
            <div className="col-md-10">
              {type === "liver" && <h1 className="title"> Liver Module</h1>}

              {loading && <TableLoaderComponent />}
              {!loading && (
                <div className="row">
                  <div className="col-sm-12 col-lg-12">
                    <ModuleStatusComponent
                      caseId={caseId}
                      type={type}
                      answered={answered}
                      total={total}
                      estimatedRemainingTime={estimatedRemainingTime}
                      user={user}
                    />
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <InfoBoxComponent
                      title="Completed Cases"
                      value={completedCases}
                    />
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <InfoBoxComponent
                      title="Total Time"
                      // value={Math.round(totalTime / 60)}
                      value={numeral(totalTime).format("00:00:00")}
                    />
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <InfoBoxComponent title="Time Spent Per Case " value={0} />
                  </div>
                  <div className="col-sm-12 col-lg-12"></div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Dashboard;
