import React from "react";

export const AnswerSideBar = (props) => {
  let {
    showNextAnswer,
    showDrawnTissue,
    currentAnswerQuality,
    currentAnswer,
    answers,
    currentAnswerIndex,
  } = props;
  return (
    <div className="sidebar-actions">
      <div className="row">
        <div className="col-md-1 hide-on-desktop"></div>
        <div className="col-lg-12 col-md-5  col-sm-5">
          <div className="frame-quality">
            <h2> Frame Quality</h2>

            <p className="col-lg-12 col-md-5  col-sm-5">
              {currentAnswerQuality}
            </p>
          </div>

          <div className="tissues-list">
            <h2> Submitted ROI</h2>
            <ul>
              {Object.keys(currentAnswer.tissues).length === 0 && (
                <li>
                  <p>Nothing Detected</p>
                </li>
              )}
              {Object.keys(currentAnswer.tissues).map((key, index) => (
                <li>
                  <p onClick={showDrawnTissue.bind(this, key)}>
                    {key.replace("_", " ")}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          {answers.length > currentAnswerIndex + 1 && (
            <button
              className="btn btn-orange next-btn"
              onClick={showNextAnswer.bind(this, currentAnswerIndex)}
            >
              Next Image
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
