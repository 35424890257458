import React from "react";

const ProfileUserInfoComponent = (props) => {
  let { user } = props;
  return (
    <div className="user-info">
      <form action className="form-data">
        <div className="row row-register">
          <div className="col-md-5 col-sm-12">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="firstName"> First Name</h2>
              </div>
              <input
                className="form-control"
                name="firstName"
                type="text"
                defaultValue={user ? user.firstName : ""}
              />
            </div>
          </div>

          <div className="col-md-5 col-sm-12">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="email"> Last Name</h2>
              </div>
              <input
                className="form-control"
                name="lastName"
                type="text"
                defaultValue={user ? user.lastName : ""}
              />
            </div>
          </div>

          <div className="col-md-5 col-sm-12">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Email Address</h2>
              </div>
              <input
                className="form-control"
                name="email"
                type="email"
                defaultValue={user ? user.email : ""}
              />
            </div>
          </div>

          <div className="col-md-5 col-sm-12">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="country"> Country</h2>
              </div>
              <input
                className="form-control"
                name="country"
                type="country"
                defaultValue={user ? user.country : ""}
              />
            </div>
          </div>

          <div className="col-md-5 col-sm-12">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Institution</h2>
              </div>
              <input
                className="form-control"
                name="institution"
                type="institution"
                defaultValue={user ? user.institution : ""}
              />
            </div>
          </div>

          <div className="col-md-5 col-sm-12">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="country"> specialization</h2>
              </div>
              <input
                className="form-control"
                name="specialization"
                type="specialization"
                defaultValue={user ? user.specialization : ""}
              />
            </div>
          </div>

          <div className="col-md-5 col-sm-12">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> title</h2>
              </div>
              <input
                className="form-control"
                name="title"
                type="title"
                defaultValue={user ? user.title : ""}
              />
            </div>
          </div>

          <div className="col-md-5 col-sm-12">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="country"> Years of experince</h2>
              </div>
              <input
                className="form-control"
                name="experince"
                type="experince"
                defaultValue={user ? user.experince : ""}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileUserInfoComponent;
