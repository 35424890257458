import React from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "~/Store/Apis/BaseUrl";
// Components
import ProfileUserInfoComponent from "./_InfoComponent";
import ProfileCasesComponent from "./_CasesComponent";
import ProfileTrainingCasesComponent from "./_TrainingCasesComponent";
import SidebarComponent from "~/Components/Sidebar";

// Actions
import "../../../../styles/Admin/Doctors/_DoctorPage.scss";

class ProfileComponent extends React.Component {
  state = {
    loading: true,
    user: null,
    cases: [],
    training: [],
    hasError: false,
    errMsg: [],
    view: "personal",
  };
  constructor(props) {
    super(props);
    this.doctorId = props.match.params.doctorId;
  }

  componentDidMount() {
    this.getDrInfo();
    this.getDrLiverCases();
  }

  getDrInfo = () => {
    axios
      .get(`${baseUrl}/doctors/${this.doctorId}`)
      .then((res) => {
        if (res.data.success === true) {
          const user = res.data.data;
          console.log("user data", user);
          this.setState({
            loading: false,
            user: user,
            training: user.answers,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "Server Error ",
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  getDrLiverCases = () => {
    axios
      .get(`${baseUrl}/doctors/${this.doctorId}/liverCases`)
      .then((res) => {
        if (res.data.success === true) {
          const cases = res.data.data;
          console.log("user cases", cases);
          this.setState({
            loading: false,
            cases: cases,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "Server Error ",
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  toogleRowExtraInfo = (index) => {
    const extraRow = document.getElementById(`extra-info-${index}`);
    const classList = extraRow.classList;
    const toogleExtraInfoBtn = document.getElementById(
      `toogle-row-extra-info-${index}`
    );
    if (classList.contains("hide-this")) {
      toogleExtraInfoBtn.innerHTML = "-";
      extraRow.classList.remove("hide-this");
      extraRow.classList.add("m-fadeIn");
    } else {
      toogleExtraInfoBtn.innerHTML = "+";
      extraRow.classList.add("hide-this");
    }
  };

  changeView = (view) => {
    let activeclass = document.querySelectorAll(".active");
    for (var i = 0; i < activeclass.length; i++) {
      activeclass[i].classList.remove("active");
    }

    const viewElement = document.getElementById(`view-${view}`);
    viewElement.classList.add("active");

    this.setState({
      view,
    });
  };

  render() {
    let { view, loading, cases, hasError, user, training } = this.state;
    return (
      <div className="profile">
        <SidebarComponent activeItem="doctor" type="admin" />

        <section className="page">
          <div className="row top-bar">
            <div className="col-md-1 mt-auto" />
            <div className="col-md-7">
              <h3>
                <Link
                  to="#"
                  className="no-style"
                  onClick={() => this.props.history.goBack()}
                >
                  <i class="fas fa-caret-left"></i> Back to Dashboard{" "}
                </Link>
              </h3>
            </div>
            <div className="col-md-3">
              <ul className="profile-sections-list">
                <li
                  onClick={this.changeView.bind(this, "personal")}
                  id="view-personal"
                  className="active"
                >
                  Personal{" "}
                </li>
                <span>|</span>
                <li
                  onClick={this.changeView.bind(this, "training")}
                  id="view-training"
                  className="n"
                >
                  Training{" "}
                </li>
                <span>|</span>
                <li
                  onClick={this.changeView.bind(this, "cases")}
                  id="view-cases"
                  className="n"
                >
                  Cases{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="doctors">
            <div className="row page-title">
              <div className="col-md-1" />
              <div className="col-md-3">
                <h1 className="header">Dr Profile Page</h1>
                {/* <button className="btn btn-orange ">Assign Case</button> */}
              </div>
            </div>

            <div className="row user-info">
              <div className="col-md-1" />
              <div className="col-md-10">
                {loading && <h1> Loading..</h1>}

                {!loading && !hasError && (
                  <>
                    {view === "cases" && (
                      <ProfileCasesComponent
                        loading={loading}
                        cases={cases}
                        toogleRowExtraInfo={this.toogleRowExtraInfo}
                      />
                    )}

                    {view === "training" && (
                      <ProfileTrainingCasesComponent
                        loading={loading}
                        answers={training}
                        toogleRowExtraInfo={this.toogleRowExtraInfo}
                      />
                    )}

                    {view === "personal" && (
                      <ProfileUserInfoComponent user={user} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default ProfileComponent;
