import React from "react";
import { Link } from "react-router-dom";
import { dateFormat, isOlderThanWeekAgo } from "~/Helpers/dateHelper";

const DoctorsTable = (props) => {
  let { type, fieldNamesMapper, sortColoumn, loading, doctors } = props;
  console.log("Dta is", doctors);
  return (
    <div className="table-overflow">
      <table className={`${loading ? "hide-this" : ""}`}>
        <tr id="t01">
          <th>
            NAME{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.name)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            Country{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.country)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            Register Date{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.createdAt)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            Last Day Active{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.updatedAt)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            Training Module
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.status)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>Active Case </th>
        </tr>
        {doctors.map((dr, index) => {
          return (
            <>
              <tr className={index % 2 === 0 ? "" : "greyBG"}>
                <td class="namelink">
                  <Link to={`/admin/doctors/${dr._id}?type=${type}`}>
                    {`${dr.firstName} ${dr.lastName}`}
                  </Link>
                </td>
                <td>{dr.country}</td>
                <td>{dateFormat(dr.createdAt)}</td>
                <td>
                  {isOlderThanWeekAgo(dr.updatedAt) ? (
                    <span style={{ color: "red" }}>
                      {dateFormat(dr.updatedAt)}{" "}
                    </span>
                  ) : (
                    <span>{dateFormat(dr.updatedAt)} </span>
                  )}
                </td>
                <td>
                  {dr.examStatus === "review" ? (
                    <Link to={`/admin/tm/${dr.userId}/answers`}>
                      <button className="btn btn-green btn-review">
                        Review
                      </button>
                    </Link>
                  ) : (
                    <>{dr.examStatus}</>
                  )}
                </td>

                <td>N/A</td>
              </tr>
            </>
          );
        })}
      </table>
    </div>
  );
};

export default DoctorsTable;
