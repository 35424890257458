import React from "react";
import TissuesList from "./_TissuesList";
import DrawnTissuesList from "./_DrawnTissuesList";

const ExamSidebar = (props) => {
  let {
    tissues,
    selectTissue,
    saveTissueCordinates,
    showDrawnTissue,
    removeTissue,
    answerQuestion,
    currentTissueSelected,
    currentQuestionIndex,
    questions,
  } = props;
  return (
    <div className="sidebar-actions">
      <div className="row">
        <div className="col-sm-1 hide-on-desktop"></div>

        <div className="col-lg-12 col-md-5 col-sm-5"></div>

        <div className="col-lg-12 col-md-5 col-sm-5">
          <div
            className="form-group col-lg-12 col-md-12"
            id="tissues-list-select"
          >
            <p>Add ROI for all tissues in the image.</p>
            <TissuesList selectTissue={selectTissue} />
            {/* <i className="fas fa-plus" onClick={saveTissueCordinates} /> */}
          </div>
          <div className="tissues-list col-lg-12 col-md-12">
            <DrawnTissuesList
              tissues={tissues}
              showDrawnTissue={showDrawnTissue}
              removeTissue={removeTissue}
            />
          </div>
        </div>

        <div className="col-lg-12 col-md-5 col-sm-5">
          {currentTissueSelected !== "nothing-detected" && (
            <>
              <button
                className="btn btn-orange save-roi-btn"
                onClick={saveTissueCordinates}
                id="save-roi-btn"
              >
                Save ROI
              </button>
              <button
                className="btn btn-orange save-roi-btn"
                id="save-roi-btn-overlay"
              >
                Save ROI
              </button>
            </>
          )}
          <>
            <button
              className="btn btn-orange next-btn"
              id="complete-image-btn"
              onClick={answerQuestion}
            >
              {questions.length === currentQuestionIndex + 1
                ? "Complete Training"
                : "Next Image"}
            </button>

            <button
              className="btn btn-orange next-btn"
              id="complete-image-btn-overlay"
            >
              {questions.length === currentQuestionIndex + 1
                ? "Complete Training"
                : "Next Image"}
            </button>
          </>
        </div>
      </div>
    </div>
  );
};

export default ExamSidebar;
