import React from "react";
import { Link } from "react-router-dom";

const ResetPassowordSuccess = (props) => {
  return (
    <>
      <h1 className="title">Password Reset Successful</h1>
      <p className="body-copy">Your password has been reset!</p>
      <form className="form-data">
        <div className="row row-login">
          <div className="forget-password-success-img">
            <img
              src="/images/resetPasswordSuccess.svg"
              alt="reset password success"
            />
          </div>
        </div>
        <hr className="auth-seprator" />
        <Link to="/">
          <button type="submit" className="btn btn-large btn-orange login">
            Home
          </button>
        </Link>
      </form>
    </>
  );
};

export default ResetPassowordSuccess;
