import React from "react";
import store2 from "store2";
import { Redirect, Route } from "react-router-dom";

const AuthRoute = ({ component: Component, auth, ...rest }) => {
  const token = store2.get("token");
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Redirect to={"/"} /> : <Component {...props} />
      }
    />
  );
};

export default AuthRoute;
