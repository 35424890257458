import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import store2 from "store2";

const DrMiniSidebar = (props) => {
  // let { logout, activeItem, changeWidthType, nameInitials } = props;
  let { logout, activeItem } = props;
  let [isChangeView, toogleChangeViewHandler] = useState(false);

  let user = store2.get("user");

  return (
    <section className="mini-sidebar left-sidebar">
      {isChangeView && (
        <div className="change-view-menu">
          <ul className="">
            <li className="sidebar-item">
              <Link to="#" className="no-style">
                Doctor
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/admin" className="no-style">
                Admin
              </Link>
            </li>
          </ul>
        </div>
      )}

      <ul className="menu">
        <li className="">
          <Link to="/" className="no-style">
            <img className="side-logo" src="/images/logo_circle.png" alt="" />
          </Link>
          <hr />
        </li>

        {user && user.type === "admin" && (
          <li
            data-tip
            data-for="change-view"
            className="sidebar-item change-view"
            onClick={() => toogleChangeViewHandler(!isChangeView)}
          >
            <ReactTooltip id="change-view">
              <span> Change view</span>
            </ReactTooltip>
            Doctor
          </li>
        )}

        {/* <li className="name">{nameInitials}</li> */}
        <li
          // title="Dashboard"
          data-tip
          data-for="dashboard"
          className={`${activeItem === "exam" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="dashboard">
            <span>Dashboard</span>
          </ReactTooltip>
          <Link to="/" className="no-style">
            <i className="fa fa-th-large"></i>
          </Link>
        </li>
        <li
          // title="Profile"
          data-tip
          data-for="profile"
          className={`${activeItem === "profile" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="profile">
            <span>Profile</span>
          </ReactTooltip>
          <Link to="/profile" className="no-style">
            <i className="fa fa-user"></i>
          </Link>
        </li>
        <li></li>
        <li className="logout" onClick={logout}>
          <Link to="/login">
            <span>Sign Out </span>
          </Link>
          {/* <i className="fas fa-power-off" onClick={logout}></i> */}
        </li>
      </ul>
    </section>
  );
};
export default DrMiniSidebar;
