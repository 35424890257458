import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Fuse from "fuse.js";

// Components
import SidebarComponent from "~/Components/Sidebar";
import TableLoaderComponent from "~/Components/Loaders/_TableLoaderComponent";
import { baseUrl } from "~/Store/Apis/BaseUrl";
import LiverCasesTable from "~/Components/LiverModule/_Table";
import TableHeaderActions from "~/Components/LiverModule/_TableHeaderActions";
import TableFooter from "~/Components/Doctors/_TableFooter";

// Scss
import "~/styles/Admin/TrainingModule/_DoctorsListPage.scss";

const fieldNamesMapper = {
  name: "firstName",
  country: "country",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
  status: "examStatus",
};

class DoctorsListComponent extends React.Component {
  state = {
    loading: true,
    doctors: [],
    filtered_doctors: [],
    hasError: false,
    error_msg: null,
    sortingStatus: "asc",
    page: 1,
    limit: 5,
  };

  componentDidMount() {
    this.getAllLiverCases();
  }

  getAllLiverCases = () => {
    let { limit, page } = this.state;
    axios
      .get(`${baseUrl}/liver?page=${page}&limit=${limit}`)
      .then((res) => {
        console.log("respone is", res.data.data.docs);
        if (res.data.success === true) {
          if (res.data.data.docs.length > 0) {
            this.setState({
              loading: false,
              doctors: res.data.data.docs,
              filtered_doctors: res.data.data.docs,
              totalDocs: res.data.data.totalDocs,
              totalPages: res.data.data.totalPages,
            });
          } else {
            this.setState({
              loading: false,
              hasError: true,
              error_msg: "No Data Found ",
            });
          }
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: res.data.message,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  filterByName = (e) => {
    const { doctors } = this.state;
    const value = e.target.value;
    if (value.length === 0) {
      this.setState({
        filtered_doctors: doctors,
      });
      return;
    }
    var options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["firstName", "lastName"],
    };
    var fuse = new Fuse(doctors, options);
    var result = fuse.search(value);
    this.setState({
      filtered_doctors: result,
    });
  };

  sortBy = (input) => {
    let fieldName = input.target.value;
    console.log(input, fieldName);
    this.sortColoumn(fieldName);
  };

  sortColoumn = (coulumnFieldName) => {
    let { doctors, sortingStatus } = this.state;
    if (sortingStatus === "asc") {
      doctors.sort((a, b) =>
        a[coulumnFieldName] > b[coulumnFieldName] ? 1 : -1
      );
      this.setState({
        filtered_doctors: doctors,
        sortingStatus: "dsc",
      });
    } else {
      doctors.sort((a, b) =>
        a[coulumnFieldName] > b[coulumnFieldName] ? -1 : 1
      );
      this.setState({
        filtered_doctors: doctors,
        sortingStatus: "asc",
      });
    }
  };

  paginate = (page) => {
    this.setState(
      {
        page,
      },
      () => {
        this.getAllLiverCases();
      }
    );
  };

  updateLimit = (limit) => {
    this.setState(
      {
        limit,
      },
      () => {
        this.getAllLiverCases();
      }
    );
  };

  render() {
    let {
      loading,
      filtered_doctors,
      page,
      totalPages,
      totalDocs,
      limit,
    } = this.state;

    return (
      <div className="doctors-list-page">
        <SidebarComponent activeItem="liver" type="admin" />

        <section className="page">
          <div className="row top-bar">
            <div className="col-md-1 mt-auto" />
            <div className="col-md-11 ">
              <h3>
                <Link
                  to="#"
                  className="no-style"
                  onClick={() => this.props.history.goBack()}
                >
                  <i class="fas fa-caret-left"></i> Back to Dashboard{" "}
                </Link>
              </h3>
            </div>
          </div>
          <div className="doctors">
            <div className="row page-title">
              <div className="col-md-1" />
              <div className="col-md-8">
                <h1 className="header">Data Quality</h1>
              </div>
            </div>
            <TableHeaderActions
              loading={loading}
              sortBy={this.sortBy}
              filterByName={this.filterByName}
            />
            <div className="row doctors-table">
              <div className="col-md-1" />
              <div className="col-md-10">
                {loading && <TableLoaderComponent />}

                {!loading && (
                  <LiverCasesTable
                    fieldNamesMapper={fieldNamesMapper}
                    sortColoumn={this.sortColoumn}
                    loading={loading}
                    doctors={filtered_doctors}
                    // doctors={[]}
                    toogleAllRows={this.toogleAllRows}
                    sortByGrade={this.sortByGrade}
                    toogleRowExtraInfo={this.toogleRowExtraInfo}
                  />
                )}
              </div>
            </div>
            <TableFooter
              totalPages={totalPages}
              totalDocs={totalDocs}
              limit={limit}
              page={page}
              loading={loading}
              paginate={this.paginate}
              updateLimit={this.updateLimit}
              filterByName={this.filterByName}
            />
          </div>
        </section>
      </div>
    );
  }
}
export default DoctorsListComponent;
