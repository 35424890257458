import React from "react";
import { Link } from "react-router-dom";
import { dateFormat } from "~/Helpers/dateHelper";
import numeral from "numeral";

const toogleRowExtraInfo = (index) => {
  const extraRow = document.getElementById(`extra-info-${index}`);
  const classList = extraRow.classList;
  const toogleExtraInfoBtn = document.getElementById(
    `toogle-row-extra-info-${index}`
  );
  if (classList.contains("hide-this")) {
    toogleExtraInfoBtn.innerHTML = "-";
    extraRow.classList.remove("hide-this");
    extraRow.classList.add("m-fadeIn");
  } else {
    toogleExtraInfoBtn.innerHTML = "+";
    extraRow.classList.add("hide-this");
  }
};

const toogleAllRows = (noOfRows) => {
  for (let index = 0; index < noOfRows; index++) {
    toogleRowExtraInfo(index);
  }
};

let usability = (liverCase) => {
  console.log("Usablity", liverCase.NoOfUnusable, liverCase.NoOfQuestions);
  return (
    100 - Math.floor((liverCase.NoOfUnusable / liverCase.NoOfQuestions) * 100)
  );
};

let duration = (duration) => {
  return numeral(duration).format("00:00:00");
};

const DoctorsTable = (props) => {
  let { fieldNamesMapper, sortColoumn, loading, doctors } = props;
  return (
    <div className="table-overflow">
      <table className={`${loading ? "hide-this" : ""}`}>
        <tr id="t01">
          <th
            class="plusheader"
            onClick={toogleAllRows.bind(this, doctors.length)}
          >
            +
          </th>
          <th>
            Case #{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.name)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            NAME{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.name)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            START DATE{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.date)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            TIME{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.time)}
              class="fas fa-sort"
            ></i>
          </th>

          <th>
            usability{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.grade)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            STATUS{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.status)}
              class="fas fa-sort"
            ></i>
          </th>
        </tr>
        {doctors.map((liverCase, index) => {
          return (
            <>
              <tr className={index % 2 === 0 ? "" : "greyBG"}>
                <td
                  class="plus"
                  onClick={toogleRowExtraInfo.bind(this, index)}
                  id={`toogle-row-extra-info-${index}`}
                >
                  +
                </td>
                <td class="namelink">
                  <Link
                    to={`/admin/liver/${liverCase.caseId}/${liverCase.user[0]._id}/answers`}
                  >
                    #{liverCase.caseId}
                  </Link>
                </td>
                <td class="namelink">
                  <Link to={`/admin/doctors/${liverCase.user[0]._id}`}>
                    {`${liverCase.user[0].firstName} ${liverCase.user[0].lastName}`}
                  </Link>
                </td>
                <td>{dateFormat(liverCase.startDate)}</td>
                <td>{duration(liverCase.duration)}</td>
                <td>{usability(liverCase)}%</td>
                <td>
                  {liverCase.status === "complete" ? (
                    <Link to="#">
                      <button className="btn btn-green btn-review">
                        Complete
                      </button>
                    </Link>
                  ) : (
                    <>{liverCase.status.replace("_", " ")}</>
                  )}
                </td>
              </tr>
              <tr
                className={`${index % 2 === 0 ? "" : "greyBG"}  hide-this`}
                id={`extra-info-${index}`}
              >
                <td colspan="8">
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2 left-align">
                      <h2>Region</h2>
                      <p>{liverCase.country}</p>
                    </div>
                    <div className="col-md-3 left-align">
                      <h2>Time</h2>
                      <p>Time: {duration(liverCase.duration)}</p>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          );
        })}
      </table>
    </div>
  );
};

export default DoctorsTable;
