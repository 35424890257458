import React from "react";
import { Link } from "react-router-dom";
const StepThreeComponents = (props) => {
  return (
    <div class="login-form">
      <h1 class="title">Step 3: Verify Your Email</h1>
      <p class="body-copy">
        A verification link has been sent to your email. Please activate it and
        sign into your account. If you do not receive an email, please check
        your spam or junk folder.
      </p>
      <form action="" class="form-data">
        <div class="row row-register-3">
          <button type="button" class="btn btn-orange-border ">
            Resend Verification
          </button>
        </div>
      </form>
      <hr class="auth-seprator" />
      <Link to="/login">
        <button type="button" class="btn  btn-orange btn-bottom">
          Sign In
        </button>
      </Link>
    </div>
  );
};

export default StepThreeComponents;
