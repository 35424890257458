import React from "react";
import { Link } from "react-router-dom";
import { Progress } from "antd";

const ModuleStatusComponent = (props) => {
  const { caseId, type, answered, total, estimatedRemainingTime, user } = props;

  let percent = Math.floor((answered / total) * 100);
  let title = "Training Module";
  let startBtnLink = "tm-exam";
  let description = `Label as many tissues as you see in the image, with particular attention on the liver. Images are frames from videos which are prone to noise and shadowing. Select “Nothing Detected” from the tissue selection drop down if you are unable to detect tissues.`;

  if (type === "liver") {
    title = `Case #${caseId}`;
    startBtnLink = `liver-exam`;
  }

  if (percent === 100 && type !== "liver") {
    title = "Training Module COmpleted";
    description =
      "Thank you! Your training module is being assessed. You will be notified by email when your assessment is complete.";
  } else if (type === "liver" && user.status === "confirmed-unassigned") {
    title = "Liver Exam COmpleted";
    description = "Thank you! Your Liver Exam is being completed";
  }

  return (
    <div className="dr-info-table">
      <div className="row">
        <div className=" col-lg-6 col-md-6 ">
          <div className="status-info">
            <h2>{title}</h2>
            <p>{description}</p>
            {user.status !== "confirmed-unassigned" && (
              <div>
                {percent < 100 && (
                  <Link to={startBtnLink}>
                    <button className="btn btn-orange  ">Start</button>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
        {user.status !== "confirmed-unassigned" && (
          <div className=" col-lg-6 col-md-6 ">
            <div className="row">
              <div className=" col-md-12  col-lg-6 ">
                <div className="progress">
                  <Progress
                    type="circle"
                    percent={percent}
                    format={(percent) => `${percent}% Complete`}
                  />
                </div>
              </div>
              <div className=" col-md-12 col-lg-6 ">
                <div className="time">
                  <h4> Estimated Time Remaining </h4>
                  <p className="estimated-time">
                    {Math.round(estimatedRemainingTime / 60)}{" "}
                  </p>
                  <p>Minutes</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ModuleStatusComponent;
