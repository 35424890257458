import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "~/Store/Apis/BaseUrl";
import store2 from "store2";

// Components
import "~/styles/_auth.scss";

class ForgetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      loginCode: null,
      showLoginCode: false,
      errMsg: "",
      hasBackendError: false,
      backendErrorMsg: "",
      loading: false,
      form: {
        email: "",
      },
    };

    this.token = props.match.params.token;
  }

  componentDidMount() {
    document.title = "Oncoustics | Veifying";
    this.verifyAccount();
  }

  verifyAccount = () => {
    axios
      .post(
        `${baseUrl}/auth/verify`,
        {},
        {
          headers: {
            token: this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          const user = res.data.user;
          console.log("user data", user);
          this.setState({
            loading: false,
            token: this.token,
            type: res.data.type,
          });
          this.setTokenAndRedirect();
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "Server Error ",
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  setTokenAndRedirect = () => {
    store2.set("token", this.state.token);
    store2.set("type", this.state.type);
    this.props.history.push("/");
    window.location.reload();
  };

  render() {
    return (
      <section>
        <div className="row page">
          <div className="col-lg-3  col-md-12 left-sidebar">
            <div id="particles-js" />
          </div>
          <div className="col-md-12 top-sidebar"></div>
          <div className="col-md-1 col-md-0"></div>
          <div className="col-lg-7 col-md-9  main-section">
            <ul className="header-menu">
              <li>
                <Link to="/login">
                  <img
                    className="logo"
                    src="/images/logo_full.png"
                    alt="logo"
                  />
                </Link>
              </li>
              <li className="header-links">
                <p>
                  Don't have an account?{" "}
                  <Link to="/register" className="bold">
                    Sign Up
                  </Link>
                </p>
              </li>
            </ul>
            <div className="login-form">
              <h1 className="title">Your account has been verified!</h1>
              <p className="body-copy">
                You can now sign into your account from the sign in page.
              </p>
              <form className="form-data">
                <div className="row row-login">
                  <div className="forget-password-success-img">
                    <img
                      src="/images/resetPasswordSuccess.svg"
                      alt="reset password success"
                    />
                  </div>
                </div>
              </form>
              <hr className="auth-seprator" />
              <Link to="/">
                <button type="submit" className="btn  btn-orange btn-bottom">
                  Sign in
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-1 col-md-0"></div>
        </div>
      </section>
    );
  }
}

export default ForgetPasswordComponent;
