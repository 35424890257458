import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const DrMiniSidebar = (props) => {
  // let { logout, activeItem, changeWidthType, nameInitials } = props;
  let { logout, activeItem } = props;

  let [isChangeView, toogleChangeViewHandler] = useState(false);

  return (
    <section class="mini-sidebar left-sidebar">
      {isChangeView && (
        <div className="change-view-menu">
          <ul className="">
            <li className="sidebar-item">
              <Link to="/" className="no-style">
                Doctor
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/#" className="no-style">
                Admin
              </Link>
            </li>
          </ul>
        </div>
      )}

      <ul class="menu">
        <li className="">
          <Link to="/admin" className="no-style">
            <img class="side-logo" src="/images/logo_circle.png" alt="" />
          </Link>
          <hr />
        </li>

        <li
          data-tip
          data-for="change-view"
          className="sidebar-item change-view"
          onClick={() => toogleChangeViewHandler(!isChangeView)}
        >
          <ReactTooltip id="change-view">
            <span> Change view</span>
          </ReactTooltip>
          Admin
        </li>
        {/* Admin */}
        <li
          data-tip
          data-for="dashboard"
          className={`${activeItem === "admin" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="dashboard">
            <span> Dashboard</span>
          </ReactTooltip>
          <Link to="/admin" className="no-style">
            <i class="fa fa-th-large"></i>
          </Link>
        </li>
        {/* Training Module */}
        <li
          data-tip
          data-for="tm"
          className={`${activeItem === "tm" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="tm">
            <span>Training Module</span>
          </ReactTooltip>
          <Link to="/admin/tm" className="no-style">
            <img
              src={`${
                activeItem === "tm" ? "/images/tm-white.svg" : "/images/tm.svg"
              }`}
              alt="right circle"
              className="svg-image"
            />
          </Link>
        </li>
        {/* Cases */}
        <li
          data-tip
          data-for="liver"
          className={`${activeItem === "liver" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="liver">
            <span>Liver Module</span>
          </ReactTooltip>
          <Link to="/admin/liver" className="no-style">
            <img
              src={`${
                activeItem === "liver"
                  ? "/images/cases-white.svg"
                  : "/images/cases.svg"
              }`}
              alt="right circle"
              className="svg-image"
            />
          </Link>
        </li>
        <li></li>
        {/* <li className="logout">
          <i class="fas fa-power-off" onClick={logout}></i>
        </li> */}
        <li className="logout" onClick={logout}>
          <span>Sign Out </span>
          {/* <i class="fas fa-power-off" onClick={logout}></i> */}
        </li>
      </ul>
    </section>
  );
};
export default DrMiniSidebar;
