import React from "react";
import { Link } from "react-router-dom";
import store2 from "store2";
import axios from "axios";
import { baseUrl } from "~/Store/Apis/BaseUrl";
// Components
import LoginCodeComponent from "./LoginCodeComponent";
import LoginFormComponent from "./LoginFormComponent";
import "~/styles/_auth.scss";

class LoginComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
      loginCode: null,
      showLoginCode: false,
      errMsg: "",
      hasBackendError: false,
      backendErrorMsg: [],
      liverCaseId: null,
      firstName: null,
      lastName: null,
      rememberMe: false,
      form: {
        email: "",
        password: "",
      },
    };
  }

  componentDidMount() {
    document.body.className = "animsition page-login-v2 layout-full page-dark";
    document.title = "Oncoustics | Login";
    if (store2.has("remeber_me")) {
      let savedCreds = store2.get("remeber_me");
      this.setState({
        form: savedCreds,
      });
      console.log("saved Creds", savedCreds);
    }
  }

  handleInputChange = (event) => {
    const form = { ...this.state.form };
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  handleRememberMe = () => {
    this.setState({
      rememberMe: !this.state.rememberMe,
    });
  };

  submit = (e) => {
    e.preventDefault();

    const { email, password } = {
      ...this.state.form,
    };
    let errMsg = [];
    if (!email && email.length < 1) {
      errMsg.push("email");
    }
    if (!password && password.length < 1) {
      errMsg.push("password");
    }

    if (errMsg.length > 0) {
      this.setState({
        errMsg,
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
        errMsg: [],
        hasBackendError: false,
      });
      this.login();
    }
  };

  login = () => {
    const { rememberMe, form } = this.state;
    const { email, password } = form;
    if (rememberMe) {
      store2.set("remeber_me", {
        email,
        password,
      });
    }
    axios
      .post(`${baseUrl}/auth/login`, { email, password })
      .then((response) => {
        let { success, message, data } = response.data;
        if (success) {
          console.log("Login Code", response.data.data.loginCode);
          this.setState({
            hasBackendError: false,
            liverCaseId: data.liverCaseId,
            loginCode: data.loginCode,
            token: response.data._token,
            type: data.type,
            firstName: data.firstName,
            lastName: data.lastName,
            hasError: false,
            errMsg: "",
            user: data,
          });
          if (data.loginCode === null) {
            this.setTokenAndRedirect();
          } else {
            this.setState({ showLoginCode: true });
          }
        } else {
          this.setState({
            hasBackendError: true,
            backendErrorMsg: message,
          });
        }
      })
      .catch((err) => {
        console.log("EMM____", err);
        this.setState({
          hasBackendError: true,
        });
      });
  };

  enterLoignCode = (e) => {
    e.preventDefault();
    const { loginCode } = this.state.form;
    if (loginCode === this.state.loginCode) {
      this.setTokenAndRedirect();
    } else {
      this.setState({
        hasError: true,
        errMsg: "Login Code Incorrect",
      });
    }
  };

  setTokenAndRedirect = () => {
    let { token, type, firstName, lastName, user } = this.state;

    store2.set("token", token);
    store2.set("type", type);
    store2.set("firstName", firstName);
    store2.set("lastName", lastName);
    store2.set("user", user);

    if (type !== "user") {
      this.props.history.push("/");
    } else {
      this.props.history.push("/Admin");
    }
    window.location.reload();
  };

  render() {
    const { hasError, errMsg, hasBackendError, backendErrorMsg, form } =
      this.state;
    return (
      <section>
        <div className="row page">
          <div className="col-lg-3  col-md-12 left-sidebar">
            <div id="particles-js" />
          </div>
          <div className="col-md-12 top-sidebar"></div>
          <div className="col-md-1 col-md-0"></div>
          <div className="col-lg-7 col-md-9  main-section">
            <ul className="header-menu">
              <li>
                <img className="logo" src="/images/logo_full.png" alt="logo" />
              </li>
              <li className="header-links">
                <p>
                  Don't have an account?{" "}
                  <Link to="/register" className="bold">
                    Sign Up
                  </Link>
                </p>
              </li>
            </ul>
            {this.state.showLoginCode && (
              <LoginCodeComponent
                hasError={hasError}
                errMsg={errMsg}
                enterLoignCode={this.enterLoignCode}
                handleInputChange={this.handleInputChange}
              />
            )}

            {!this.state.showLoginCode && (
              <LoginFormComponent
                form={form}
                hasBackendError={hasBackendError}
                backendErrorMsg={backendErrorMsg}
                errMsg={errMsg}
                handleInputChange={this.handleInputChange}
                handleRememberMe={this.handleRememberMe}
                submit={this.submit}
              />
            )}
          </div>
          <div className="col-lg-1 col-md-0 "></div>
        </div>
      </section>
    );
  }
}

export default LoginComponent;
