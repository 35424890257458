import React from "react";
import { Link } from "react-router-dom";

import { dateFormat } from "~/Helpers/dateHelper";

const ProfileCasesComponent = (props) => {
  let { loading, cases, toogleRowExtraInfo } = props;
  let usability = (liverCase) => {
    return (
      100 - Math.floor((liverCase.NoOfUnusable / liverCase.NoOfQuestions) * 100)
    );
  };

  return (
    <table className={`${loading ? ".hide-this" : ""}`}>
      <tr id="t01">
        <th class="plusheader">+</th>
        <th>Case</th>
        <th>START DATE</th>
        <th>End DATE</th>
        <th>TIME</th>
        <th>Usability</th>
        <th>Status </th>
      </tr>
      {cases.map((liverCase, index) => {
        return (
          <>
            <tr className={index % 2 === 0 ? "" : "greyBG"}>
              <td
                class="plus"
                onClick={toogleRowExtraInfo.bind(this, index)}
                id={`toogle-row-extra-info-${index}`}
              >
                +
              </td>
              <td class="namelink">{liverCase.caseId}</td>
              <td>{dateFormat(liverCase.startDate)}</td>
              <td>{dateFormat(liverCase.endDate)}</td>
              <td>{liverCase.duration / 60}</td>
              <td>{usability(liverCase)}%</td>
              <td>
                {/* <button className="btn btn-green btn-extra-info">Review</button> */}
                {liverCase.status === "complete" ? (
                  <Link to="#">
                    <button className="btn btn-green btn-review">
                      Complete
                    </button>
                  </Link>
                ) : (
                  <>{liverCase.status.replace("_", " ")}</>
                )}
              </td>
            </tr>
          </>
        );
      })}
    </table>
  );
};

export default ProfileCasesComponent;
