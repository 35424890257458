import React from "react";
import axios from "axios";
import moment from "moment";
import CanvasDraw from "react-canvas-draw";

import store2 from "store2";
import domtoimage from "dom-to-image";

// import "intro.js/introjs.css";
// Components
import ExamLoaderComponent from "~/Components/Loaders/_ExamLoaderComponent";
import Popup from "~/Components/Popup";
import ExamStructure from "./_ExamStructure";
import ExamSidebar from "./_ExamSidebar";
// Actions
import "~/styles/_exam.scss";
import { baseUrl } from "~/Store/Apis/BaseUrl";

// Original Image Width
const BWImageWidth = 1679;
const BWImageHeigt = 1267;

// 13 Inch
let canvasWidth = 700;
let canvasHeight = 530;

const windowWidth = window.innerWidth;

// Ipad
if (windowWidth < 800) {
  console.log("SMALLERR!!");
  canvasWidth = 600;
  canvasHeight = 454;
}
// 15 Inch
if (windowWidth > 1600) {
  canvasWidth = 900;
  canvasHeight = 680;
}

console.log("Width is__", windowWidth, canvasWidth);

const JWT_TOKEN = store2.get("token");

// const TEST_MODULE_ID = "__5e2b138cebc22f06d92f0a0d";

class TestExamComponent extends React.Component {
  questionsAnswers = [];
  state = {
    overlayFirstTime: false, // first try to answer
    loading: true,
    nextQuestionLoader: false,
    questions: [],
    allowLabeling: false,
    currentTissueSelected: null,
    currentTissueSelectedCount: 1,
    hasError: false,
    error_msg: null,
    currentQuestionIndex: 0,
    currentQuestion: null,
    startTime: null,
    timer: 1,
    timerIntervalId: null,
    maskedDivs: [],
    drawnTissueCord: null,
    dontShowPopAgain: false,
    showCompleteImagePopup: false,
    showPauseExamPopup: false,
    trainingModuleCompleted: false,
  };
  componentDidMount() {
    this.getTestModulequestions();
    if (windowWidth < 800) {
      document.body.classList.add("no-scroll");
    }
  }

  startTimer() {
    let { timerIntervalId } = this.state;
    // if theres old inetrval, clear it
    if (timerIntervalId) {
      console.log("Clearing Interval");
      clearInterval(timerIntervalId);
      this.setState({
        timer: 1,
      });
    }

    let IntervalId = setInterval(() => {
      let { timer } = this.state;
      this.setState({
        timer: timer + 1,
      });
      console.log("Timer");
    }, 1000);
    this.setState({
      startTime: moment.now(),
      timerIntervalId: IntervalId,
    });
  }

  getTestModulequestions = () => {
    axios
      .get(`${baseUrl}/tm/questions`, {
        headers: {
          token: JWT_TOKEN,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.questions.length > 0) {
            const questions = res.data.questions;
            const tmCount = res.data.tmCount;
            const overlayFirstTime = questions.length === tmCount;
            const q2 = questions.map((q) => {
              q.tissues = {};
              q.maskedDivs = [];
              return q;
            });
            this.setState({
              // startTime: moment.now(),
              loading: false,
              questions: q2,
              currentQuestion: questions[0],
              overlayFirstTime,
            });
            this.startTimer();
          } else {
            // this.props.history.push("/");
            // window.location.reload();
            this.setState({
              loading: false,
              hasError: true,
              error_msg: "No More Questions ",
              questions: [],
              currentQuestion: {},
            });
          }
        } else {
          console.log(res.data);
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "ERROR ",
            questions: [],
            currentQuestion: {},
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          hasError: true,
          error_msg: "Internal Server Error",
          questions: [],
          currentQuestion: {},
        });
        return err;
      });
  };

  selectTissue = (input) => {
    const tissue = input.target.value;
    this.canvasRef.clear();
    if (tissue === "none" || tissue === "nothing-detected") {
      this.notTissueDetcted();
      return;
    }
    this.setState({ allowLabeling: true, currentTissueSelected: tissue });
  };

  selectQuality = (input) => {
    const quality = input.target.value;
    this.setState({ currentQualitySelected: quality });
  };

  notTissueDetcted = () => {
    let { currentQuestion, questions, currentQuestionIndex } = this.state;

    currentQuestion.maskedDivs = [];
    currentQuestion.tissues = {};
    questions[currentQuestionIndex] = currentQuestion;

    this.setState({
      allowLabeling: false,
      currentTissueSelected: "nothing-detected",
      questions: questions,
      currentQuestion: currentQuestion,
    });
  };

  saveTissueCordinates = () => {
    let {
      currentTissueSelected,
      currentQuestion,
      questions,
      currentQuestionIndex,
      currentTissueSelectedCount,
    } = this.state;
    const cordinates = JSON.parse(this.canvasRef.getSaveData());

    if (cordinates.lines.length < 1) {
      this.setState({
        hasError: true,
        allowLabeling: true,
        error_msg: "Please Select a tissue and start labeling ",
      });
      this.canvasRef.clear();
      return;
    }

    const points = cordinates.lines[0].points;

    const hypotenuse = this.getHypotenuse(points);
    if (hypotenuse > 20) {
      this.setState({
        hasError: true,
        allowLabeling: true,
        error_msg: "Please make sure to seal the selection ",
      });
      this.canvasRef.clear();
      return;
    }

    // check if tissue already exist, make it tissue_2
    let tissueName = `${currentTissueSelectedCount}. ${currentTissueSelected}`;
    if (currentQuestion.tissues[tissueName]) {
      console.log("Tissue Already exit, renaming current tissue name");
      tissueName = `${currentTissueSelectedCount + 1
        }. ${currentTissueSelected}`;
    }
    this.setState({
      currentTissueSelectedCount: currentTissueSelectedCount + 1,
    });
    const newMaskedDiv = this.generateMaskedImageStyles(
      points,
      currentQuestion,
      tissueName
    );

    let maskedDivs = currentQuestion.maskedDivs;
    maskedDivs.push(newMaskedDiv);

    currentQuestion.maskedDivs = maskedDivs;
    currentQuestion.tissues[tissueName] = cordinates;
    questions[currentQuestionIndex] = currentQuestion;

    this.setState(
      {
        questions: questions,
        currentQuestion: currentQuestion,
        allowLabeling: true,
      },
      () => {
        this.canvasRef.clear();
      }
    );

    setTimeout(() => {
      this.downloadMaskedImage(this[`${tissueName}Ref`]);
    }, 500);
  };

  undoROI = () => {
    this.canvasRef.clear();
    this.setState({
      allowLabeling: true,
    });
  };

  generateMaskedImageStyles = (points, question, tissue) => {
    let tissuePointsString = "";
    let tissuePointsStringWithExtraPixels = "";

    points.map((t) => {
      tissuePointsStringWithExtraPixels += `${(t.x * BWImageWidth) / canvasWidth
        }px ${(t.y * BWImageHeigt) / canvasHeight}px ,`;
      return t;
    });
    tissuePointsString = tissuePointsString.substring(
      0,
      tissuePointsString.length - 1
    );
    tissuePointsStringWithExtraPixels =
      tissuePointsStringWithExtraPixels.substring(
        0,
        tissuePointsStringWithExtraPixels.length - 1
      );
    const BWContainerStyle = {
      width: `${1679}px`,
      height: `${1267}px`,
      backgroundColor: "black",
      "max-width": "none",
      "max-height": "none"
    };

    const BWContainerStyleBig = {
      width: `${1679}px`,
      height: `${1267}px`,
      backgroundColor: "black",
      "max-width": "none",
      "max-height": "none"
    };
    const imgStyles = {
      maxWidth: `${canvasWidth}px`,
      height: "auto",
      filter: "brightness(0) invert(1)",
      clipPath: `polygon(
                ${tissuePointsString}
              )`,
    };
    const imgStyles2 = {
      filter: "brightness(0) invert(1)",
      clipPath: `polygon(
                ${tissuePointsStringWithExtraPixels}
              )`,
    };
    return {
      BWContainerStyle,
      BWContainerStyleBig,
      imgStyles,
      imgStyles2,
      imageUrl: question.imageUrl,
      tissue,
    };
  };

  getHypotenuse = (points) => {
    const firstCordDot = points[0];
    const lastCordDot = points[points.length - 1];

    var a = firstCordDot.x - lastCordDot.x;
    var b = firstCordDot.y - lastCordDot.y;

    return Math.sqrt(a * a + b * b);
  };

  removeTissue = (tissueName) => {
    let { currentQuestion, questions, currentQuestionIndex } = this.state;
    delete currentQuestion.tissues[tissueName];
    questions[currentQuestionIndex] = currentQuestion;
    this.setState({
      questions: questions,
      allowLabeling: true,
    });
    this.canvasRef.clear();
  };

  showDrawnTissue = (tissueName) => {
    let { currentQuestion, questions } = this.state;
    this.canvasRef.clear();
    const tissueCord = currentQuestion.tissues[tissueName];

    this.canvasRef.loadSaveData(JSON.stringify(tissueCord));
    this.setState({
      questions: questions,
      drawnTissueCord: tissueCord,
    });
  };

  /**
   * this happend when the user removes his hand from mouse
   */
  mouseOverOnImage = () => {
    this.setState({
      allowLabeling: false,
    });
  };

  closeCompleteImagePopup = () => {
    console.log("clossing..");
    this.setState({
      showCompleteImagePopup: false,
    });
  };

  dontShowcloseCompleteImagePopup = () => {
    const { dontShowPopAgain } = this.state;
    this.setState({
      dontShowPopAgain: !dontShowPopAgain,
    });
  };

  answerQuestion = () => {
    const {
      currentQuestionIndex,
      questions,
      startTime,
      currentQuestion,
      currentTissueSelected,
      dontShowPopAgain,
    } = this.state;
    if (
      Object.keys(currentQuestion.tissues).length < 1 &&
      currentTissueSelected !== "nothing-detected"
    ) {
      this.setState({
        hasError: true,
        error_msg:
          "Please select one tissue to submit, or select “Nothing Detected” from the drop down menu before completing the image.",
      });
      return;
    }
    const endTime = moment.now();
    currentQuestion.duration = moment.duration(endTime - startTime).asSeconds();
    questions[currentQuestionIndex] = currentQuestion;
    this.setState({
      questions: questions,
      currentQuestion,
      allowLabeling: false,
    });

    if (dontShowPopAgain) {
      this.saveAnswer();
    } else {
      console.log("Opening..");
      this.setState({
        showCompleteImagePopup: true,
      });
    }
  };

  saveAnswer = () => {
    const { currentQuestionIndex, currentQuestion, questions } = this.state;
    this.setState({
      nextQuestionLoader: true,
      showCompleteImagePopup: false,
    });

    let answer = {
      questionId: currentQuestion._id,
      tissues: currentQuestion.tissues,
      duration: currentQuestion.duration,
    };

    axios
      .post(
        `${baseUrl}/tm/answers`,
        {
          answer,
        },
        {
          headers: {
            token: JWT_TOKEN,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          if (questions.length === currentQuestionIndex + 1) {
            this.setState({
              trainingModuleCompleted: true,
              nextQuestionLoader: false,
            });
            return;
          }

          this.setState({
            nextQuestionLoader: false,
            // startTime: moment.now(),
            currentQuestionIndex: currentQuestionIndex + 1,
            currentQuestion: questions[currentQuestionIndex + 1],
            currentTissueSelectedCount: 1,
          });
          this.startTimer();
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: [res.data.message],
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  hideAlert = () => {
    this.setState({
      hasError: false,
    });
  };

  downloadMaskedImage = (node) => {
    console.log("ref is", node);
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        link.href = dataUrl;
        link.click();
        return dataUrl;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  canvasClicked = () => {
    if (!this.state.allowLabeling) {
      this.setState({
        hasError: true,
        error_msg: "Please Select a tissue to draw",
      });
    }
  };

  pauseExam = () => {
    this.setState({
      showPauseExamPopup: true,
    });
  };

  goBackToDashboard = () => {
    this.props.history.push("/");
    // this.props.history.push("/");
    // window.location.reload();
  };

  cancelPauseExam = () => {
    this.setState({
      showPauseExamPopup: false,
    });
  };

  resetOverlayFirstTry = () => {
    this.setState({
      overlayFirstTime: false,
    });
  };

  render() {
    const {
      overlayFirstTime,
      loading,
      questions,
      currentQuestionIndex,
      currentQuestion,
      nextQuestionLoader,
      allowLabeling,
      hasError,
      error_msg,
      drawnTissueCord,
      currentTissueSelected,
      showCompleteImagePopup,
      showPauseExamPopup,
      trainingModuleCompleted,
      timer,
    } = this.state;
    const question = currentQuestion;

    if (loading || nextQuestionLoader) {
      return (
        <ExamStructure
          currentQuestionIndex={currentQuestionIndex}
          questions={questions}
        >
          <div className="col-lg-1 col-sm-1" />
          <div className="col-lg-10">
            <h1 className="header">Training Module</h1>
            <ExamLoaderComponent />
          </div>
          <div className="col-lg-3 "></div>
        </ExamStructure>
      );
    }

    if (!loading && questions.length < 1) {
      return (
        <ExamStructure
          currentQuestionIndex={currentQuestionIndex}
          questions={questions}
        >
          <div className="col-lg-1 col-sm-1" />
          <div className="col-lg-10">
            <h1 className="header">Training Module</h1>
            <Popup
              message="Training Module Complete, Return to Dashboard"
              type="exam"
              confirm={this.goBackToDashboard}
              confirmTitle="Return"
            />
          </div>
          <div className="col-md-3 "></div>
        </ExamStructure>
      );
    }

    return (
      <>
        <ExamStructure
          overlayFirstTime={overlayFirstTime}
          resetOverlayFirstTry={this.resetOverlayFirstTry}
          currentQuestionIndex={currentQuestionIndex}
          questions={questions}
          pauseExam={this.pauseExam}
          timer={timer}
        >
          {trainingModuleCompleted && (
            <Popup
              message="Training Module is complete! Thank you for your time."
              type="exam"
              confirm={this.goBackToDashboard}
              confirmTitle="Go To Dashboard"
            />
          )}
          {showCompleteImagePopup && (
            <Popup
              message="Are you sure you want to move to the Next Image? All submissions will be final for this image."
              type="exam"
              confirm={this.saveAnswer}
              cancel={this.closeCompleteImagePopup}
              dontShowAgain={this.dontShowcloseCompleteImagePopup}
            />
          )}
          {hasError && (
            <Popup message={error_msg} type="exam" cancel={this.hideAlert} />
          )}
          {showPauseExamPopup && (
            <Popup
              message="You can resume your test from the dashboard"
              type="exam"
              cancel={this.cancelPauseExams}
              confirm={this.goBackToDashboard}
              confirmTitle="Pause"
            />
          )}
          <div className="col-lg-1 col-sm-1" />
          <div className="col-lg-7 col-sm-11 answer-tablet">
            <h1 className="header">Training Module</h1>
            <div className="question-description">
              <p>
                Select and draw the Region of Interest (ROI) for as many tissues
                as you can see in the image.
              </p>{" "}
              <p> Select “Nothing Detected” if no tissues are observed.</p>
            </div>

            {/* <img className="liver-img" src={`${question.imageUrl}`} alt="" /> */}
            <div style={{ opacity: "0.9" }} className="canvas">
              <img
                src="/images/undo.svg"
                onClick={(e) => {
                  e.stopPropagation();
                  this.undoROI();
                }}
                alt="UNDO"
                className="undo-roi"
              />
              <p className="undo-text">Undo</p>
              <div
                onMouseDown={this.canvasClicked}
                onTouchStart={this.canvasClicked}
              >
                <CanvasDraw
                  onChange={this.mouseOverOnImage}
                  disabled={!allowLabeling}
                  ref={(canvasDraw) => (this.canvasRef = canvasDraw)}
                  brushColor="red"
                  catenaryColor="red"
                  imgSrc={`${question.imageUrl}`}
                  brushRadius={0}
                  lazyRadius={0}
                  canvasWidth={canvasWidth}
                  canvasHeight={canvasHeight}
                  className="liver-img"
                  savedData={drawnTissueCord}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-sm-1" />
          <div className="col-lg-3 col-sm-11 ">
            <ExamSidebar
              tissues={question.tissues}
              selectTissue={this.selectTissue}
              saveTissueCordinates={this.saveTissueCordinates}
              showDrawnTissue={this.showDrawnTissue}
              removeTissue={this.removeTissue}
              answerQuestion={this.answerQuestion}
              currentTissueSelected={currentTissueSelected}
              currentQuestionIndex={currentQuestionIndex}
              questions={questions}
            />
          </div>
        </ExamStructure>

        <div className="col-lg-12 col-sm-12">
          {question.maskedDivs.map((t) => {
            return (
              <>
                <div style={{ opacity: "0" }}>
                  <div
                    style={t.BWContainerStyleBig}
                    ref={(masked) => (this[`${t.tissue}Ref`] = masked)}
                  >
                    <img src={`${t.imageUrl}`} style={t.imgStyles2} alt="" />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }
}
export default TestExamComponent;
