import React, { useEffect } from "react";

const StepOne = (props) => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 800) {
    return <StepOneMobile />;
  }
  return <StepOneDesktop />;
};

const StepOneMobile = (props) => {
  return (
    <div className="overlay-gif">
      <img src="images/gif/step1.gif" alt="GIF" />
    </div>
  );
};

const StepOneDesktop = (props) => {
  // Ipad
  console.log("IPAD Overlay!!");
  var tissuesListElement = document.getElementById("tissues-list-select");
  var topPos = tissuesListElement.getBoundingClientRect().top + window.scrollY;
  var leftPos =
    tissuesListElement.getBoundingClientRect().left + window.scrollX;
  tissuesListElement.style.cssText =
    "position:absolute; z-index:9999999999999999999";

  let styles = {
    top: `${topPos + 100}px`,
    left: `${leftPos}px`,
  };

  // when unmounting the component
  useEffect(() => {
    return () => {
      tissuesListElement.style.cssText = "";
    };
  }, [tissuesListElement]);
  return (
    <div className="overlay-steps">
      <div className="overlay-step-1">
        <div className="info" style={styles}>
          <i class="fas fa-arrow-up"></i>
          <p> Select a tissue to begin drawing its Region of Interest</p>
        </div>
      </div>
    </div>
  );
};
export default StepOne;
