import React from "react";

const ForgetPassowordForm = (props) => {
  let {
    handleInputChange,
    errMsg,
    hasBackendError,
    backendErrorMsg,
    submit,
  } = props;
  return (
    <>
      <p className="body-copy">
        Forgot your password? Enter your email address below and we will send
        you information on how to reset your password.
      </p>
      <form onSubmit={submit} className="form-data">
        <div className="row row-login">
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Enter Email Here</h2>
              </div>
              <input
                className="form-control"
                name="email"
                onChange={handleInputChange}
                placeholder="Enter email address"
              />
              {errMsg.includes("email") && (
                <span className="err-msg"> Please enter your email</span>
              )}
              {hasBackendError && (
                <span className="err-msg">{backendErrorMsg}</span>
              )}
            </div>
          </div>
        </div>
      </form>
      <hr className="auth-seprator" />
      <button  onClick={submit} type="submit" className="btn  btn-orange btn-bottom">
        Submit
      </button>
    </>
  );
};

export default ForgetPassowordForm;
