import React from "react";
import store2 from "store2";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  auth,
  permission,
  permissions,
  ...rest
}) => {
  const token = store2.get("token");
  // const secret = "th!sIs0ncoust!csS3cr3t";
  if (!token) {
    return <Redirect to={"/login"} />;
  }
  if (token.length < 20) {
    store2.remove("token");
    return <Redirect to={"/login"} />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
