import React from "react";
import StepOneCompnent from "./StepOneComponent";
import StepTwoComponent from "./StepTwoComponent";
import StepThreeComponents from "./StepThreeComponents";
const RegisterStepsComponent = (props) => {
  const { step, ...rest } = props;
  if (step === 2) {
    return <StepTwoComponent {...rest} />;
  }

  if (step === 3) {
    return <StepThreeComponents {...rest} />;
  }

  return <StepOneCompnent {...rest} />;
};

export default RegisterStepsComponent;
