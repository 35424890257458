import React from "react";

import axios from "axios";
import store2 from "store2";
import CanvasDraw from "react-canvas-draw";
import SweetAlert from "react-bootstrap-sweetalert";
import Popup from "~/Components/Popup";

// Components
import ExamLoaderComponent from "~/Components/Loaders/_ExamLoaderComponent";

import { AnswerSideBar } from "./_AnswerSidebar";
import { AnswerTimeInfo } from "./_AnswerTimeInfo";

import AnswersStructure from "./AnswersStructure";

// helpers
// import "~/styles/Admin/TrainingModule/_AnswersPage.scss";
import "~/styles/_exam.scss";
import { baseUrl } from "~/Store/Apis/BaseUrl";

// 13 Inch
let canvasWidth = 700;
let canvasHeight = 462;

const windowWidth = window.innerWidth;

// Ipad
if (windowWidth < 800) {
  console.log("SMALLERR!!");
  canvasWidth = 600;
  canvasHeight = 395;
}
// 15 Inch
if (windowWidth > 1600) {
  canvasWidth = 900;
  canvasHeight = 594;
}

console.log("Width is", windowWidth, canvasWidth);

class DoctorAnswersComponent extends React.Component {
  questionsAnswers = [];
  state = {
    preview_type: "viewing", // ['grading','viewing']
    showCompletionPopUp: false,
    loading: true,
    nextAnswerLoader: false,
    answers: [],
    hasError: false,
    error_msg: null,
    currentAnswerIndex: 0,
    currentAnswer: null,
    drawnTissueCord: null,
    inputErrorMsg: {},
    form: {
      liverGrade: "",
      minorTissueGrade: "",
      majorTissueGrade: "",
    },
  };

  constructor(props) {
    super(props);
    this.caseId = props.match.params.caseId;
    this.userId = props.match.params.userId;
  }

  componentDidMount() {
    this.getDoctorAnswers();
  }

  getDoctorAnswers = () => {
    axios
      .get(
        `${baseUrl}/liver/answers?userId=${this.userId}&caseId=${this.caseId}`
      )
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            const answers = res.data.data;
            let new_answers = answers;
            this.setState({
              loading: false,
              answers: new_answers,
              currentAnswer: new_answers[0],
            });
          } else {
            this.setState({
              loading: false,
              hasError: true,
              error_msg: "No More Questions ",
              answers: [],
              currentAnswer: {},
            });
          }
        } else {
          store2.remove("token");
          window.location.reload();
        }
      })
      .catch((err) => {
        return err;
      });
  };

  showDrawnTissue = (tissueName) => {
    this.canvasRef.clear();
    const currentAnswer = this.state.currentAnswer;
    const tissueCord = currentAnswer.tissues[tissueName];
    this.canvasRef.loadSaveData(JSON.stringify(tissueCord));
    this.setState({
      drawnTissueCord: tissueCord,
    });
  };

  showNextAnswer = (currentAnswerIndex) => {
    let answers = this.state.answers;

    if (currentAnswerIndex + 1 === answers.length) {
      return;
    }
    // check if next answser has grades
    let nextAnswer = answers[currentAnswerIndex + 1];
    this.setState({
      nextAnswerLoader: true,
      currentAnswer: nextAnswer,
      currentAnswerIndex: currentAnswerIndex + 1,
    });

    setTimeout(() => {
      this.setState({
        nextAnswerLoader: false,
      });
    }, 500);
  };

  showPreviusAnswer = (currentAnswerIndex) => {
    this.setState({
      nextAnswerLoader: true,
    });

    let answers = this.state.answers;
    if (currentAnswerIndex === 0 || currentAnswerIndex - 1 === answers.length) {
      return;
    }
    this.setState({
      currentAnswer: answers[currentAnswerIndex - 1],
      currentAnswerIndex: currentAnswerIndex - 1,
    });
    setTimeout(() => {
      this.setState({
        nextAnswerLoader: false,
      });
    }, 500);
  };

  hideAlert = () => {
    this.setState({
      hasError: false,
      error_msg: null,
    });
  };

  render() {
    const {
      preview_type,
      showCompletionPopUp,
      loading,
      answers,
      currentAnswerIndex,
      currentAnswer,
      nextAnswerLoader,
      hasError,
      error_msg,
      drawnTissueCord,
      inputErrorMsg,
      form,
    } = this.state;

    if (loading || nextAnswerLoader) {
      return (
        <AnswersStructure
          history={this.props.history}
          showPreviusAnswer={this.showPreviusAnswer}
          showNextAnswer={this.showNextAnswer}
          currentAnswerIndex={currentAnswerIndex}
          answers={answers}
        >
          <div className="col-lg-10">
            <h1 className="header">Training Module Assesment</h1>
            <ExamLoaderComponent />
          </div>
        </AnswersStructure>
      );
    }

    if (!loading && answers.length < 1) {
      return (
        <AnswersStructure
          history={this.props.history}
          showPreviusAnswer={this.showPreviusAnswer}
          showNextAnswer={this.showNextAnswer}
          currentAnswerIndex={currentAnswerIndex}
          answers={answers}
        >
          <div className="col-lg-8">
            <h1 className="header">Training Module Assesment</h1>
            <h1> {error_msg}</h1>
          </div>
        </AnswersStructure>
      );
    }

    return (
      <AnswersStructure
        history={this.props.history}
        showPreviusAnswer={this.showPreviusAnswer}
        showNextAnswer={this.showNextAnswer}
        currentAnswerIndex={currentAnswerIndex}
        answers={answers}
      >
        <>
          <div className="col-lg-8 answer-tablet">
            <h1 className="header">Training Module Assesment</h1>
            <AnswerTimeInfo currentAnswer={currentAnswer} />
            {showCompletionPopUp && (
              <Popup
                message="Complete Final Review? The Training Module can be edited at any time from the Dashboard."
                type="exam"
                confirm={this.saveGrades}
                cancel={() => {
                  this.setState({ showCompletionPopUp: false });
                }}
                confirmTitle="Complete"
                cancelTitle="Go Back"
              />
            )}
            {hasError && (
              <SweetAlert title={error_msg} onConfirm={this.hideAlert} />
            )}

            {/* <img className="liver-img" src="./images/liver.jpg" alt="" /> */}
            {nextAnswerLoader && <ExamLoaderComponent />}
            <div style={{ opacity: "0.9" }}>
              <CanvasDraw
                onChange={this.mouseOverOnImage}
                disabled={true}
                ref={(canvasDraw) => (this.canvasRef = canvasDraw)}
                brushColor="red"
                catenaryColor="red"
                imgSrc={`${currentAnswer.imageUrl}`}
                brushRadius={0}
                lazyRadius={0}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                className="liver-img"
                savedData={drawnTissueCord}
              />
            </div>
          </div>
          <div className="col-lg-3  col-sm-12">
            <AnswerSideBar
              preview_type={preview_type}
              showNextAnswer={this.showNextAnswer}
              handleInputChange={this.handleInputChange}
              showDrawnTissue={this.showDrawnTissue}
              completeImage={this.completeImage}
              currentAnswer={currentAnswer}
              currentAnswerQuality={
                currentAnswer.frameQuality ? currentAnswer.frameQuality : "NA"
              }
              answers={answers}
              form={form}
              inputErrorMsg={inputErrorMsg}
              currentAnswerIndex={currentAnswerIndex}
            />
          </div>
        </>
      </AnswersStructure>
    );
  }
}
export default DoctorAnswersComponent;
