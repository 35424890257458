import React from "react";
import Select from "react-select";
const tissuesList = [
  { value: "nothing-detected", label: "Nothing Detected" },
  { value: "Liver", label: " Liver " },
  { value: "Skin_Fat_layer", label: " Skin/Fat layer " },
  { value: "Diaphragm", label: " Diaphragm " },
  { value: "Gall_Bladder", label: " Gall Bladder " },
  { value: "Kidney", label: " Kidney " },
  { value: "Spleen", label: " Spleen " },
  { value: "Blood_vessels", label: " Blood Vessels " },
  { value: "Tumor", label: " Tumor " },
  { value: "Cyst", label: " Cyst " },
  { value: "Scar_Tissues", label: " Scar Tissue " },
  { value: "Gall_Stones", label: " Gall Stones " },
  { value: "Kidney_Stones", label: " Kidney Stones " },
  { value: "Abcess", label: " Abcess " },
  { value: "Bladder", label: " Bladder " },
  { value: "Heart", label: " Heart " },
  { value: "Small_Intestine", label: " Small Intestine " },
  { value: "Stomach", label: " Stomach " },
  { value: "Duodenum", label: " Duodenum " },
  { value: "Lymph_Nodes", label: " Lymph Nodes " },
  { value: "Adrenals", label: " Adrenals " },
  { value: "Pancreas", label: " Pancreas " },
  { value: "General_Fatty_Liver", label: "General Fatty Tissue " },
  { value: "Colon", label: " Colon " },
  { value: "Pleura", label: " Pleura " },
  { value: "Bile_Ducts", label: " Bile Ducts " },
  { value: "Shadowing", label: " Shadowing " },
  { value: "light_shadow", label: " Light Shadow " },
];
const TissuesList = (props) => {
  let { selectTissue } = props;
  const change = ({ value }) => {
    selectTissue({ target: { name: "tissue", value } });
  };
  return (
    <>
      <Select
        options={tissuesList}
        onChange={change}
        classNamePrefix="customSelect"
        className="tissue-list "
        isSearchable={false}
      // value={options.filter(({ value }) => value === myForm.mySelectKey)}
      // defaultValue={{ value: "", label: "Add  another tissue" }}
      />
    </>
  );
};

export default TissuesList;
