import React from "react";

const StepTwo = (props) => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 800) {
    return <StepTwoMobile />;
  }
  return <StepTwoDesktop />;
};

const StepTwoMobile = (props) => {
  return (
    <div className="overlay-gif">
      <img src="images/gif/step2.gif" alt="GIF" />
    </div>
  );
};

const StepTwoDesktop = (props) => {
  return (
    <div className="overlay-steps">
      <div className="overlay-step-2">
        <div className="image">
          <img src="/images/liver.jpg" className="rectangle" alt="rectangle" />
          <img
            src="/images/overlay/ellipse.svg"
            className="ellipse"
            alt="ellipse"
          />
        </div>
        <div className="info">
          <i class="fas fa-arrow-left"></i>
          <i class="fas fa-arrow-up"></i>

          <p> Draw the ROI for the selected tissue</p>
        </div>
      </div>
    </div>
  );
};
export default StepTwo;
