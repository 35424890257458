import React from "react";
import { Link } from "react-router-dom";

const ForgetPassowordSuccess = (props) => {
  return (
    <>
      <p className="body-copy">
        Your request for a password reset was submitted! Please check your email
        and follow the instructions there to reset your password.
      </p>
      <form className="form-data">
        <div className="row row-login">
          <div className="forget-password-success-img">
            <img
              src="/images/forgetPasswordSuccess.svg"
              alt="forget password success"
            />
          </div>
        </div>
        <hr className="auth-seprator" />
        <Link to="/">
          <button type="submit" className="btn  btn-orange btn-bottom">
            Home
          </button>
        </Link>
      </form>
    </>
  );
};

export default ForgetPassowordSuccess;
