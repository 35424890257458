import React from "react";
import Select from "react-select";
import CountriesListComponent from "~/Components/CountriesListComponent";

const specializationList = [
  { value: "Radiologist", label: "Radiologist" },
  { value: "Oncologists", label: "Oncologists" },
  { value: "Hepatologist", label: "Hepatologist" },
  { value: "Primary-care", label: "Primary Care" },
  { value: "Sonographer-Technician", label: "Sonographer/Technician" },
  { value: "Other", label: "Other" },
];
const experinceList = [
  { value: "1", label: "less than 1" },
  { value: "1_5", label: "1-5" },
  { value: "6_10", label: "6-10" },
  { value: "11_15", label: "11-15" },
  { value: "16_20", label: "16-20" },
  { value: "20+", label: "20 +" },
];

const ProfileUserInfoComponent = (props) => {
  let { user, updateProfile, handleInputChange, inputDisabled } = props;
  return (
    <div className="user-info">
      <form action className="form-data" onSubmit={updateProfile}>
        <div className="row row-register">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="firstName"> First Name</h2>
              </div>
              <input
                className={`form-control ${inputDisabled ? "view-input" : ""} `}
                onChange={handleInputChange}
                name="firstName"
                type="text"
                defaultValue={user.firstName}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="email"> Last Name</h2>
              </div>
              <input
                onChange={handleInputChange}
                name="lastName"
                type="text"
                className={`form-control ${inputDisabled ? "view-input" : ""} `}
                defaultValue={user.lastName}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Email Address</h2>
              </div>
              <input
                onChange={handleInputChange}
                name="email"
                type="email"
                className={`form-control ${inputDisabled ? "view-input" : ""} `}
                defaultValue={user.email}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="country"> Country</h2>
              </div>
              {inputDisabled ? (
                <input
                  onChange={handleInputChange}
                  name="country"
                  type="country"
                  className={`form-control ${
                    inputDisabled ? "view-input" : ""
                  } `}
                  defaultValue={user.country}
                />
              ) : (
                <CountriesListComponent
                  handleInputChange={handleInputChange}
                  defaultValue={user.country}
                  className={`form-control ${
                    inputDisabled ? "view-input" : ""
                  } `}
                />
              )}
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Institution</h2>
              </div>
              <input
                onChange={handleInputChange}
                name="institution"
                type="institution"
                className={`form-control ${inputDisabled ? "view-input" : ""} `}
                defaultValue={user.institution}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="country"> Specialization</h2>
              </div>
              {inputDisabled ? (
                <input
                  onChange={handleInputChange}
                  name="specialization"
                  type="specialization"
                  className={`form-control ${
                    inputDisabled ? "view-input" : ""
                  } `}
                  defaultValue={user.specialization}
                />
              ) : (
                <Select
                  options={specializationList}
                  onChange={({ value }) =>
                    handleInputChange({
                      target: { name: "specialization", value },
                    })
                  }
                  classNamePrefix="customSelect"
                  defaultValue={specializationList.filter(
                    (option) => option.value === user.specialization
                  )}
                />
              )}
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Title</h2>
              </div>
              <input
                onChange={handleInputChange}
                name="title"
                type="title"
                className={`form-control ${inputDisabled ? "view-input" : ""} `}
                defaultValue={user.title}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="country"> Years of Experience</h2>
              </div>
              {inputDisabled ? (
                <input
                  onChange={handleInputChange}
                  name="experince"
                  type="experince"
                  className={`form-control ${
                    inputDisabled ? "view-input" : ""
                  } `}
                  defaultValue={user.experince ? user.experince.replace("_", "-") : ""} 
                />
              ) : (
                <Select
                  options={experinceList}
                  onChange={({ value }) =>
                    handleInputChange({ target: { name: "experince", value } })
                  }
                  classNamePrefix="customSelect"
                  defaultValue={experinceList.filter(
                    (option) => option.value === user.experince
                  )}
                />
              )}
            </div>
          </div>
        </div>
        {/* <hr className="auth-seprator" /> */}
        {!inputDisabled && (
          <button
            type="submit"
            className="btn btn-large btn-orange login"
            onClick={updateProfile}
          >
            Update
          </button>
        )}
      </form>
    </div>
  );
};

export default ProfileUserInfoComponent;
