import React from "react";
import CountriesListComponent from "~/Components/CountriesListComponent";

const StepOneCompnent = (props) => {
  const { errMsg, handleInputChange, goToStepTwo } = props;
  return (
    <div className="login-form">
      <h1 className="title">Step 1: Personal</h1>
      <p className="body-copy">
        To start setting up your account, please enter in the following
        information.
      </p>
      <form action className="form-data">
        <div className="row row-register">
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="firstName"> First Name</h2>
              </div>

              <input
                className={`form-control ${
                  errMsg.includes("firstName") ? "red-label" : ""
                }`}
                onChange={handleInputChange}
                name="firstName"
                type="text"
                placeholder="Enter first name"
              />
              {errMsg.includes("firstName") && (
                <span className="err-msg">Please enter your first name. </span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="email"> Last Name</h2>
              </div>
              <input
                className={`form-control ${
                  errMsg.includes("lastName") ? "red-label" : ""
                }`}
                onChange={handleInputChange}
                name="lastName"
                type="text"
                placeholder="Enter last name"
              />
              {errMsg.includes("lastName") && (
                <span className="err-msg">Please enter your last name. </span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Email Address</h2>
              </div>
              <input
                className={`form-control ${
                  errMsg.includes("email") ? "red-label" : ""
                } ${errMsg.includes("unique_email") ? "red-label" : ""}
                ${errMsg.includes("not_valid_email") ? "red-label" : ""}`}
                onChange={handleInputChange}
                name="email"
                type="email"
                placeholder="Enter email address"
              />

              {errMsg.includes("unique_email") && (
                <span className="err-msg">
                  This email has already been registered
                </span>
              )}

              {errMsg.includes("email") && (
                <span className="err-msg">
                  Please enter a valid email address.{" "}
                </span>
              )}
              {errMsg.includes("not_valid_email") &&
                !errMsg.includes("email") && (
                  <span className="err-msg">Proper email must be used.</span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="country"> Country</h2>
              </div>

              <CountriesListComponent
                errMsg={errMsg}
                handleInputChange={handleInputChange}
              />
              {errMsg.includes("country") && (
                <span className="err-msg">Please select a country. </span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="password"> Password</h2>
              </div>
              <input
                className={`form-control ${
                  errMsg.includes("password") ? "red-label" : ""
                }`}
                onChange={handleInputChange}
                name="password"
                type="password"
                placeholder="Enter password"
              />
              {errMsg.includes("password") && (
                <span className="err-msg">Please enter a valid password. </span>
              )}
              <p className="password-requirements">
                Passwords must be eight characters or longer, with at least one
                capital letter and one number.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="confirm_password">Confirm Password</h2>
              </div>
              <input
                className={`form-control ${
                  errMsg.includes("confirm_password") ? "red-label" : ""
                }`}
                name="confirm_password"
                onChange={handleInputChange}
                type="password"
                placeholder="Enter password again"
              />
              {errMsg.includes("confirm_password") && (
                <span className="err-msg">Passwords do not match. </span>
              )}
            </div>
          </div>
        </div>
      </form>
      <hr className="auth-seprator" />
      {/* <a onClick={goToStepTwo} href="#"> */}
      <button
        onClick={goToStepTwo}
        type="button"
        className="btn  btn-orange btn-bottom"
      >
        Step 2
      </button>
      {/* </a> */}
    </div>
  );
};
export default StepOneCompnent;
