import React, { useEffect } from "react";

const StepFive = (props) => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 800) {
    return <StepFiveMobile />;
  }
  return <StepFiveDesktop />;
};

const StepFiveMobile = (props) => {
  return (
    <div className="overlay-gif">
      <img src="images/gif/step5.gif" alt="GIF" />
    </div>
  );
};

const StepFiveDesktop = (props) => {
  let styles = {};
  // Ipad
  var completeBtnElement = document.getElementById("complete-image-btn");
  completeBtnElement.style.cssText = `display:none`;

  var completeBtnOverlayElement = document.getElementById(
    "complete-image-btn-overlay"
  );
  completeBtnOverlayElement.style.cssText = `display:block;
      position:absolute;
      z-index:9999999999999999999;
      bottom:0%;`;

  var topPos =
    completeBtnOverlayElement.getBoundingClientRect().top + window.scrollY;
  var leftPos =
    completeBtnOverlayElement.getBoundingClientRect().left + window.scrollX;

  styles = {
    top: `${topPos + 70}px`,
    left: `${leftPos}px`,
  };

  // when unmounting the component
  useEffect(() => {
    return () => {
      completeBtnElement.style.cssText = "";
      completeBtnOverlayElement.style.cssText = "";
    };
  }, [completeBtnElement, completeBtnOverlayElement]);

  return (
    <>
      <div className="overlay-steps">
        <div className="overlay-step-5">
          <div className="info" style={styles}>
            <i class="fas fa-arrow-up"></i>
            <p>
              After submitting all of the ROI you see in the image, select “Next
              Image” to move on until the test is complete.
            </p>
            <i class="fas fa-arrow-down"></i>
          </div>
        </div>
      </div>
    </>
  );
};
export default StepFive;
