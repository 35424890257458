import React from "react";
import { Link } from "react-router-dom";

const LoginFormComponent = (props) => {
  let {
    submit,
    hasBackendError,
    backendErrorMsg,
    handleInputChange,
    handleRememberMe,
    errMsg,
    form,
  } = props;
  return (
    <div className="login-form">
      <h1 className="title">Sign In</h1>
      <form action className="form-data" onSubmit={submit}>
        <div className="row row-login">
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Email</h2>
              </div>
              <input
                className={`form-control ${
                  errMsg.includes("email") ? "red-label" : ""
                }`}
                name="email"
                type="email"
                placeholder="Enter email address"
                onChange={handleInputChange}
                defaultValue={form.email}
              />
              {errMsg.includes("email") && (
                <span className="err-msg">Valid email must be used</span>
              )}
              {hasBackendError && backendErrorMsg.errorCode === 0 && (
                <span className="err-msg">
                  {
                    backendErrorMsg.loginErrorMessageCode[
                      backendErrorMsg.errorCode
                    ]
                  }
                </span>
              )}
              {hasBackendError && backendErrorMsg.errorCode === 1 && (
                <span className="err-msg">
                  {
                    backendErrorMsg.loginErrorMessageCode[
                      backendErrorMsg.errorCode
                    ]
                  }
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group group-right">
              <div className="input-options">
                <h2 htmlFor="email"> Password</h2>
                <span>
                  <Link to="/forget-password" className="bold">
                    <h6>Forgot Password?</h6>
                  </Link>
                </span>
              </div>
              <input
                className={`form-control ${
                  errMsg.includes("email") ? "red-label" : ""
                }`}
                name="password"
                type="password"
                placeholder="Enter password"
                onChange={handleInputChange}
                defaultValue={form.password}
              />
              {hasBackendError && backendErrorMsg.errorCode === 2 && (
                <span className="err-msg">
                  {
                    backendErrorMsg.loginErrorMessageCode[
                      backendErrorMsg.errorCode
                    ]
                  }
                </span>
              )}
              {errMsg.includes("password") && (
                <span className="err-msg"> Incorrect password</span>
              )}
              {/* {hasBackendError &&
                backendErrorMsg.includes("Wrong Password") && (
                  <span className="err-msg"> Incorrect password</span>
                )} */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check">
              <input
                type="checkbox"
                id="checkbox_id"
                className="form-check-input"
                onChange={handleRememberMe}
              />

              <label className="form-check-label" htmlFor="checkbox_id">
                <h3>Remember me</h3>
              </label>
            </div>
          </div>
        </div>
      </form>
      <hr className="auth-seprator" />
      <button
        onClick={submit}
        type="submit"
        className="btn  btn-orange  btn-bottom"
      >
        Sign In
      </button>
    </div>
  );
};

export default LoginFormComponent;
