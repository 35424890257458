import React, { useEffect } from "react";

const StepSix = (props) => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 800) {
    return <StepSixMobile />;
  }
  return <StepSixDesktop />;
};

const StepSixMobile = (props) => {
  return (
    <div className="overlay-gif">
      <img src="images/gif/step6.gif" alt="GIF" />
    </div>
  );
};

const StepSixDesktop = (props) => {
  // Ipad
  var pauseElement = document.getElementById("pause-element");
  var topPos = pauseElement.getBoundingClientRect().top + window.scrollY;
  var leftPos = pauseElement.getBoundingClientRect().left + window.scrollX;
  pauseElement.style.cssText = `
      background-color:white;
      position: absolute;
      z-index: 2147483647;
      background-color: white;
      width: 20%;
      text-align: center;
      padding-top: 8px;
    `;

  const styles = {
    top: `${topPos + 100}px`,
    left: `${leftPos}px`,
  };

  useEffect(() => {
    return () => {
      pauseElement.style.cssText = "";
    };
  }, [pauseElement]);

  return (
    <>
      <div className="overlay-steps">
        <div className="overlay-step-6">
          <div className="info" style={styles}>
            <i class="fas fa-arrow-up"></i>
            <p>
              You are encouraged to pause the test if you need to. You can
              resume at any time from the dashboard.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default StepSix;
