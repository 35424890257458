import React from "react";

const StepCircleComponent = (props) => {
  const { step } = props;

  return (
    <div className="register-steps">
      <ul>
        <li
          className={` numberCircle ${step >= 1 ? "numberCircle-active" : ""}`}
        >
          <i class="fas fa-check"></i>
        </li>
        <li
          className={` numberCircle ${step >= 2 ? "numberCircle-active" : ""}`}
        >
          {step >= 2 && <i class="fas fa-check"></i>}
        </li>

        <li
          className={` numberCircle ${step >= 3 ? "numberCircle-active" : ""}`}
        >
          {step >= 3 && <i class="fas fa-check"></i>}
        </li>
      </ul>
    </div>
  );
};

export default StepCircleComponent;
