import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Progress } from "antd";

// Components
import SidebarComponent from "~/Components/Sidebar";
import InfoBoxComponent from "~/Components/Boxes/InfoBoxComponent";
import BarChartComponent from "~/Components/Boxes/BarChartComponent";
import TableLoaderComponent from "~/Components/Loaders/_TableLoaderComponent";

import { baseUrl } from "~/Store/Apis/BaseUrl";

// Scss
import "~/styles/_antd.css";
import "~/styles/_dashboard.scss";

const options = {
  legend: "none",
};

class Dashboard extends React.Component {
  state = {
    loading: true,
    hasError: false,
    error_msg: null,
    ongoing: 0,
    complete: 0,

    timeline: [
      ["M", "Day", { role: "style" }],
      ["Day", 0, "orange"],
    ],
    timelinelastDays: 10,
    timelineCount: 0,
    total: 0,
    unusableCount: 0,
  };

  componentDidMount() {
    this.getLiverModuleStats();
    this.getTimeline();
  }

  getLiverModuleStats = () => {
    axios
      .get(`${baseUrl}/liver/stats`)
      .then((res) => {
        console.log("respone is", res.data);
        let response = res.data;
        if (response.success === true) {
          this.setState({
            loading: false,
            ongoing: response.ongoing,
            complete: response.complete,
            total: response.total,
            unusableCount: response.unusableCount,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  getTimeline = () => {
    let { timelinelastDays } = this.state;
    axios
      .get(`${baseUrl}/liver/timeline?days=${timelinelastDays}`)
      .then((res) => {
        const response = res.data;
        if (response.success === true) {
          //  incase of no data
          if (Object.keys(response.data).length < 1) {
            return;
          }
          let formatted_timeline = [["M", "Day", { role: "style" }]];
          let timeline = response.data;
          let timelineCount = 0;
          Object.keys(timeline).forEach((k) => {
            formatted_timeline.push([k, timeline[k], "orange"]);
            timelineCount += timeline[k];
          });
          console.log("formatted time_line", formatted_timeline);
          this.setState({
            loading: false,
            timeline: formatted_timeline,
            timelineCount: timelineCount,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: response.message,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  render() {
    const {
      loading,
      ongoing,
      complete,
      timeline,
      timelineCount,
      total,
      unusableCount,
    } = this.state;
    let unusablePercentage = Math.floor((unusableCount / total) * 100);

    return (
      <div className="dashboard">
        <SidebarComponent activeItem="liver" type="admin" />

        <section className="content">
          <div className="row page-title">
            <div className="col-md-1" />
            <div className="col-md-8">
              <h1 className="header">Good Morning, Admin Name</h1>
            </div>
          </div>

          <div className="row stats">
            <div className="col-md-1" />
            <div className="col-md-10">
              <h1 className="title"> Cases</h1>
              <Link to="/admin/liver/all">view all</Link>
              {loading && <TableLoaderComponent />}
              {!loading && (
                <div className="row ">
                  <div className="col-sm-6 col-lg-4">
                    <InfoBoxComponent
                      title="ACTIVE "
                      value={ongoing}
                      link="/admin/liver/ongoing"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <InfoBoxComponent
                      title="COMPLETED"
                      value={complete}
                      link="/admin/liver/completed"
                    />
                  </div>
                  <div className="col-sm-12 col-lg-7">
                    <BarChartComponent
                      title="Cases Completed"
                      value={timelineCount}
                      options={options}
                      data={timeline}
                    />
                  </div>
                  <div className="col-sm-12 col-lg-5">
                    <div className="progress-box">
                      <p>Data Quality</p>
                      <span> out of {total}</span>
                      <div className="row">
                        <div className="col-md-6 ">
                          <div className="progress">
                            <Progress
                              type="circle"
                              percent={unusablePercentage}
                              format={(percent) => `${percent}%  unusable`}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="usability">
                            <div className="row">
                              <div className="col-md-12 ">
                                <div className="indicator">
                                  <div className="circle orange"></div>
                                  <div> Unusable</div>
                                </div>
                              </div>
                              <div className="col-md-12 ">
                                <div className="indicator">
                                  <div className="circle grey"></div>
                                  <div> Usable</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Link to="/admin/liver/all">
                        <button className="btn-orange"> view all</button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Dashboard;
