import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const TabletSidebar = (props) => {
  let { type } = props;

  if (type === "dr") {
    return <DrSidebar {...props} />;
  } else {
    return <AdminSidebar {...props} />;
  }
};

const DrSidebar = (props) => {
  let { logout, activeItem } = props;
  let [isChangeView, toogleChangeViewHandler] = useState(false);
  return (
    <section className="tablet-sidebar">
      {isChangeView && (
        <div className="change-view-menu">
          <ul className="">
            <li className="sidebar-item">
              <Link to="/" className="no-style">
                Doctor
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/admin" className="no-style">
                Admin
              </Link>
            </li>
          </ul>
        </div>
      )}

      <ul className="menu">
        <li>
          <Link to="/" className="no-style">
            <img className="side-logo" src="/images/logo_circle.png" alt="" />
          </Link>
        </li>
        <hr />
        <li
          data-tip
          data-for="change-view"
          className="sidebar-item change-view"
          onClick={() => toogleChangeViewHandler(!isChangeView)}
        >
          <ReactTooltip id="change-view">
            <span> Change view</span>
          </ReactTooltip>
          Doctor
        </li>
        <li
          data-tip
          data-for="dashboard"
          className={`${activeItem === "exam" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="dashboard">
            <span>Dashboard</span>
          </ReactTooltip>
          <Link to="/" className="no-style">
            <i className="fa fa-th-large"></i>
          </Link>
        </li>
        <li
          data-tip
          data-for="profile"
          className={`${activeItem === "profile" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="profile">
            <span>Profile</span>
          </ReactTooltip>
          <Link to="/profile" className="no-style">
            <i className="fa fa-user"></i>
          </Link>
        </li>
        <li></li>
        <li className="logout" onClick={logout}>
          <span>Sign Out </span>
        </li>
      </ul>
    </section>
  );
};

const AdminSidebar = (props) => {
  let { logout, activeItem } = props;
  let [isChangeView, toogleChangeViewHandler] = useState(false);

  return (
    <section className="tablet-sidebar">
      {isChangeView && (
        <div className="change-view-menu">
          <ul className="">
            <li className="sidebar-item">
              <Link to="/" className="no-style">
                Doctor
              </Link>
            </li>
            <li className="sidebar-item">
              <Link to="/admin" className="no-style">
                Admin
              </Link>
            </li>
          </ul>
        </div>
      )}
      <ul className="menu">
        <li>
          <Link to="/admin" className="no-style">
            <img className="side-logo" src="/images/logo_circle.png" alt="" />
          </Link>
        </li>
        <hr />
        <li
          data-tip
          data-for="change-view"
          className="sidebar-item change-view"
          onClick={() => toogleChangeViewHandler(!isChangeView)}
        >
          <ReactTooltip id="change-view">
            <span> Change view</span>
          </ReactTooltip>
          Admin
        </li>

        {/* <li className="name">AD</li> */}
        {/* Admin */}
        <li
          data-tip
          data-for="dashboard"
          className={`${activeItem === "admin" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="dashboard">
            <span> Dashboard</span>
          </ReactTooltip>
          <Link to="/admin" className="no-style">
            <i className="fa fa-th-large"></i>
          </Link>
        </li>
        {/* Training Module */}
        <li
          data-tip
          data-for="tm"
          className={`${activeItem === "tm" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="tm">
            <span>Training Module</span>
          </ReactTooltip>
          <Link to="/admin/tm" className="no-style">
            <img
              src={`${
                activeItem === "tm" ? "/images/tm-white.svg" : "/images/tm.svg"
              }`}
              alt="right circle"
              className="svg-image"
            />
          </Link>
        </li>
        {/* Cases */}
        <li
          data-tip
          data-for="liver"
          className={`${activeItem === "liver" ? "active" : "sidebar-item"}`}
        >
          <ReactTooltip id="liver">
            <span>Liver Module</span>
          </ReactTooltip>
          <Link to="/admin/liver" className="no-style">
            <img
              src={`${
                activeItem === "liver"
                  ? "/images/cases-white.svg"
                  : "/images/cases.svg"
              }`}
              alt="right circle"
              className="svg-image"
            />
          </Link>
        </li>
        <li className="logout" onClick={logout}>
          <span>Sign Out </span>
        </li>
      </ul>
    </section>
  );
};

export default TabletSidebar;
