import React, { useEffect } from "react";

const StepThree = (props) => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 800) {
    return <StepThreeMobile />;
  }
  return <StepThreeDesktop />;
};

const StepThreeMobile = (props) => {
  return (
    <div className="overlay-gif">
      <img src="images/gif/step3.gif" alt="GIF" />
    </div>
  );
};

const StepThreeDesktop = (props) => {
  let styles = {};
  // Ipad
  var saveRoiElement = document.getElementById("save-roi-btn");

  saveRoiElement.style.cssText = `z-index:9999999999999999999;
    display:none;`;

  var saveRoiOverlayElement = document.getElementById("save-roi-btn-overlay");
  saveRoiOverlayElement.style.cssText = `z-index:9999999999999999999;
    display:block;
    position:absolute;
    top:10%`;

  let topPos =
    saveRoiOverlayElement.getBoundingClientRect().top + window.scrollY;
  let leftPos =
    saveRoiOverlayElement.getBoundingClientRect().left + window.scrollX;

  styles = {
    top: `${topPos + 70}px`,
    left: `${leftPos}px`,
  };

  // when unmounting the component
  useEffect(() => {
    return () => {
      saveRoiOverlayElement.style.cssText = "";
      saveRoiElement.style.cssText = "";
    };
  }, [saveRoiOverlayElement, saveRoiElement]);
  return (
    <>
      <div className="overlay-steps">
        <div className="overlay-step-3">
          <div className="info" style={styles}>
            <i class="fas fa-arrow-up"></i>
            <p>Press Save ROI to submit the drawing.</p>
            <i class="fas fa-arrow-down"></i>
          </div>
        </div>
      </div>
    </>
  );
};
export default StepThree;
