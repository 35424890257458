import React from "react";
import axios from "axios";
import moment from "moment";
import CanvasDraw from "react-canvas-draw";
import store2 from "store2";
import domtoimage from "dom-to-image";

// import "intro.js/introjs.css";
// Components
import ExamLoaderComponent from "~/Components/Loaders/_ExamLoaderComponent";
import Popup from "~/Components/Popup";
import ExamStructure from "./_ExamStructure";
import ExamSidebar from "./_ExamSidebar";
// Actions
import "~/styles/_exam.scss";
import { baseUrl } from "~/Store/Apis/BaseUrl";

// Original Image Width
const BWImageWidth = 1679;
const BWImageHeigt = 1267;

// 13 Inch
let canvasWidth = 700;
let canvasHeight = 530;

const windowWidth = window.innerWidth;

// Ipad
if (windowWidth < 800) {
  console.log("SMALLERR!!");
  canvasWidth = 600;
  canvasHeight = 454;
}
// 15 Inch
if (windowWidth > 1600) {
  canvasWidth = 900;
  canvasHeight = 680;
}
console.log("Width is:", windowWidth, canvasWidth);

const JWT_TOKEN = store2.get("token");
// const TEST_MODULE_ID = "__5e2b138cebc22f06d92f0a0d";

class TestExamComponent extends React.Component {
  questionsAnswers = [];
  state = {
    loading: true,
    nextQuestionLoader: false,
    questions: [],
    allowLabeling: false,
    currentTissueSelected: null,
    currentQualitySelected: null,
    currentTissueSelectedCount: 1,
    hasError: false,
    error_msg: null,
    currentQuestionIndex: 0,
    currentQuestion: null,
    startTime: null,
    timer: 1,
    timerIntervalId: null,
    maskedDivs: [],
    drawnTissueCord: null,
    dontShowPopAgain: false,
    showPauseExamPopup: false,
    showCompleteImagePopup: false,
    tissuesBWImages: {},
    isTissueImageGenerated: false,
  };
  componentDidMount() {
    this.getTestModulequestions();
    if (windowWidth < 800) {
      document.body.classList.add("no-scroll");
    }
  }

  startTimer() {
    let { timerIntervalId } = this.state;
    // if theres old inetrval, clear it
    if (timerIntervalId) {
      console.log("Clearing Interval");
      clearInterval(timerIntervalId);
      this.setState({
        timer: 1,
      });
    }

    let IntervalId = setInterval(() => {
      let { timer } = this.state;
      this.setState({
        timer: timer + 1,
      });
    }, 1000);
    this.setState({
      startTime: moment.now(),
      timerIntervalId: IntervalId,
    });
  }
  getTestModulequestions = () => {
    axios
      .get(`${baseUrl}/liver/questions`, {
        headers: {
          token: JWT_TOKEN,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            const questions = res.data.data;
            const q2 = questions.map((q) => {
              q.tissues = {};
              q.maskedDivs = [];
              return q;
            });
            this.setState({
              // startTime: moment.now(),
              loading: false,
              questions: q2,
              currentQuestion: questions[0],
            });
            this.startTimer();
          } else {
            this.setState({
              loading: false,
              hasError: true,
              error_msg: "No More Questions ",
              questions: [],
              currentQuestion: {},
            });
          }
        } else {
          console.log(res.data);
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "ERROR ",
            questions: [],
            currentQuestion: {},
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          hasError: true,
          error_msg: "Internal Server Error",
          questions: [],
          currentQuestion: {},
        });
        return err;
      });
  };

  /**
   * choose a tissue
   */
  selectTissue = (input) => {
    const tissue = input.target.value;
    this.canvasRef.clear();
    if (tissue === "none" || tissue === "nothing-detected") {
      this.setState({
        isTissueImageGenerated: true,
      });
      this.notTissueDetcted();
      return;
    }
    this.setState({
      allowLabeling: true,
      currentTissueSelected: tissue,
      isTissueImageGenerated: false,
    });
  };
  selectQuality = (input) => {
    const quality = input.target.value;
    this.setState({ currentQualitySelected: quality });
  };

  notTissueDetcted = () => {
    let { currentQuestion, questions, currentQuestionIndex } = this.state;

    currentQuestion.maskedDivs = [];
    currentQuestion.tissues = {};
    questions[currentQuestionIndex] = currentQuestion;

    this.setState({
      allowLabeling: false,
      currentTissueSelected: "nothing-detected",
      questions: questions,
      currentQuestion: currentQuestion,
    });
  };

  saveTissueCordinates = () => {
    let {
      currentTissueSelected,
      currentQuestion,
      questions,
      currentQuestionIndex,
      currentTissueSelectedCount,
    } = this.state;
    const cordinates = JSON.parse(this.canvasRef.getSaveData());
    const points = cordinates.lines[0] ? cordinates.lines[0].points : [];
    if (points.length > 0) {
      const hypotenuse = this.getHypotenuse(points);
      if (hypotenuse > 50) {
        this.setState({
          hasError: true,
          allowLabeling: true,
          error_msg: "Please make sure to seal the selection ",
        });
        this.canvasRef.clear();
        return;
      }
      let tissueName = `${currentTissueSelectedCount}. ${currentTissueSelected}`;
      if (currentQuestion.tissues[tissueName]) {
        console.log("Tissue Already exit, renaming current tissue name");
        tissueName = `${
          currentTissueSelectedCount + 1
        }. ${currentTissueSelected}`;
      }
      this.setState({
        currentTissueSelectedCount: currentTissueSelectedCount + 1,
        isTissueImageGenerated: false,
      });

      const newMaskedDiv = this.generateMaskedImageStyles(
        points,
        currentQuestion,
        tissueName
      );

      let maskedDivs = currentQuestion.maskedDivs;
      maskedDivs.push(newMaskedDiv);

      currentQuestion.maskedDivs = maskedDivs;
      currentQuestion.tissues[tissueName] = cordinates;
      questions[currentQuestionIndex] = currentQuestion;

      this.setState(
        {
          questions: questions,
          currentQuestion: currentQuestion,
          allowLabeling: true,
        },
        () => {
          this.canvasRef.clear();
        }
      );

      setTimeout(() => {
        this.generateBase64ImgFromDiv(this[`${tissueName}Ref`], tissueName);
      }, 500);
    }
  };

  undoROI = () => {
    this.canvasRef.clear();
    this.setState({
      allowLabeling: true,
    });
  };

  generateMaskedImageStyles = (points, question, tissue) => {
    let tissuePointsStringWithExtraPixels = "";

    points.map((t) => {
      tissuePointsStringWithExtraPixels += `${
        (t.x * BWImageWidth) / canvasWidth
      }px ${(t.y * BWImageHeigt) / canvasHeight}px ,`;
      return t;
    });
    tissuePointsStringWithExtraPixels =
      tissuePointsStringWithExtraPixels.substring(
        0,
        tissuePointsStringWithExtraPixels.length - 1
      );
    const BWContainerStyle = {
      width: `${1679}px`,
      height: `${1267}px`,
      backgroundColor: "black",
      "max-width": "none",
      "max-height": "none",
    };

    const BWContainerStyleBig = {
      width: `${1679}px`,
      height: `${1267}px`,
      backgroundColor: "black",
      "max-width": "none",
      "max-height": "none",
    };
    const imgStyles = {
      width: `${1679}px`,
      height: `${1267}px`,
      filter: "brightness(0) invert(1)",
      clipPath: `polygon(
                ${tissuePointsStringWithExtraPixels}
              )`,
    };
    return {
      BWContainerStyle,
      BWContainerStyleBig,
      imgStyles,
      imageUrl: question.imageUrl,
      tissue,
    };
  };

  getHypotenuse = (points) => {
    const firstCordDot = points[0];
    const lastCordDot = points[points.length - 1];

    var a = firstCordDot.x - lastCordDot.x;
    var b = firstCordDot.y - lastCordDot.y;

    return Math.sqrt(a * a + b * b);
  };

  removeTissue = (tissueName) => {
    let { currentQuestion, questions, currentQuestionIndex, tissuesBWImages } =
      this.state;
    let images = tissuesBWImages;
    delete currentQuestion.tissues[tissueName];
    delete images[tissueName];
    questions[currentQuestionIndex] = currentQuestion;
    this.setState({
      questions: questions,
      allowLabeling: true,
      tissuesBWImages: images,
    });
    console.log(questions);
    this.canvasRef.clear();
  };

  showDrawnTissue = (tissueName) => {
    let { currentQuestion, questions } = this.state;
    this.canvasRef.clear();
    const tissueCord = currentQuestion.tissues[tissueName];

    this.canvasRef.loadSaveData(JSON.stringify(tissueCord));
    this.setState({
      questions: questions,
      drawnTissueCord: tissueCord,
    });
  };

  /**
   * this happend when the user removes his hand from mouse
   */
  mouseOverOnImage = () => {
    this.setState({
      allowLabeling: false,
    });
  };

  closeCompleteImagePopup = () => {
    this.setState({
      showCompleteImagePopup: false,
    });
  };

  dontShowcloseCompleteImagePopup = () => {
    const { dontShowPopAgain } = this.state;
    this.setState({
      dontShowPopAgain: !dontShowPopAgain,
    });
  };

  answerQuestion = () => {
    const {
      currentQuestionIndex,
      questions,
      startTime,
      currentQuestion,
      currentTissueSelected,
      dontShowPopAgain,
    } = this.state;
    if (
      Object.keys(currentQuestion.tissues).length < 1 &&
      currentTissueSelected !== "nothing-detected"
    ) {
      this.setState({
        hasError: true,
        error_msg: "Please select one tissue",
      });
      return;
    }
    const endTime = moment.now();
    currentQuestion.duration = moment.duration(endTime - startTime).asSeconds();
    questions[currentQuestionIndex] = currentQuestion;
    this.setState({
      questions: questions,
      currentQuestion,
      allowLabeling: false,
    });

    if (dontShowPopAgain) {
      this.saveAnswer();
    } else {
      console.log("Opening..");
      this.setState({
        showCompleteImagePopup: true,
      });
    }
  };

  getDoctorsStats = () => {
    axios
      .get(`${baseUrl}/auth/dashboard`, {
        headers: {
          token: JWT_TOKEN,
        },
      })
      .then((res) => {
        console.log("respone is", res.data);
        const response = res.data;
        if (response.success === true) {
          let {
            type,
            caseId,
            total,
            answered,
            completedCases,
            totalTime,
            CaseRemainingQuestions,
            estimatedRemainingTime,
          } = response.info;
          this.setState({
            loading: false,
            user: response.user,
            type,
            caseId,
            total,
            answered,
            completedCases,
            totalTime,
            CaseRemainingQuestions,
            estimatedRemainingTime,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: response.message,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  saveAnswer = () => {
    const {
      currentQuestionIndex,
      currentQuestion,
      currentQualitySelected,
      questions,
      tissuesBWImages,
    } = this.state;
    this.setState({
      nextQuestionLoader: true,
      showCompleteImagePopup: false,
    });

    let tissues = currentQuestion.tissues;
    axios
      .post(
        `${baseUrl}/liver/answers`,
        {
          tissues,
          imageName: currentQuestion.image,
          tissuesBWImages,
          duration: currentQuestion.duration,
          frameQuality: currentQualitySelected,
        },
        {
          headers: {
            token: JWT_TOKEN,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          if (questions.length === currentQuestionIndex + 1) {
            this.goBackToDashboard();
          }
          this.setState({
            tissuesBWImages: {},
            isTissueImageGenerated: false,
            nextQuestionLoader: false,
            // startTime: moment.now(),
            currentQuestionIndex: currentQuestionIndex + 1,
            currentQuestion: questions[currentQuestionIndex + 1],
            currentTissueSelectedCount: 1,
          });
          this.startTimer();
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: [res.data.message],
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  hideAlert = () => {
    this.setState({
      hasError: false,
    });
  };

  generateBase64ImgFromDiv = (nodeRef, tissueName) => {
    let { tissuesBWImages } = this.state;
    let images = tissuesBWImages;
    // console.log("ref is", nodeRef);
    console.log("Starting Generating Tissue For", tissueName, nodeRef);
    domtoimage
      .toPng(nodeRef)
      .then((dataUrl) => {
        console.log("Tissue URL", tissueName, dataUrl);
        images[tissueName] = dataUrl;
        this.setState({
          tissuesBWImages: images,
          isTissueImageGenerated: true,
        });
      })
      .catch(function (error) {
        console.error(
          "oops, something went wrong! In creating image from Dom",
          error
        );
      });
    console.log("Images are generated");
  };

  canvasClicked = () => {
    if (!this.state.allowLabeling) {
      this.setState({
        hasError: true,
        error_msg: "Please Select a tissue to draw",
      });
    }
  };

  pauseExam = () => {
    this.setState({
      showPauseExamPopup: true,
    });
  };

  goBackToDashboard = () => {
    this.props.history.push("/");
  };

  cancelPauseExam = () => {
    this.setState({
      showPauseExamPopup: false,
    });
  };

  render() {
    const {
      loading,
      questions,
      currentQuestionIndex,
      currentQuestion,
      nextQuestionLoader,
      allowLabeling,
      hasError,
      error_msg,
      drawnTissueCord,
      currentTissueSelected,
      showPauseExamPopup,
      showCompleteImagePopup,
      timer,
    } = this.state;

    if (loading || nextQuestionLoader) {
      return (
        <ExamStructure
          currentQuestionIndex={currentQuestionIndex}
          questions={questions}
        >
          <div className="col-lg-1 col-sm-1" />
          <div className="col-lg-10">
            <h1 className="header">Liver Module</h1>
            <ExamLoaderComponent />
          </div>
          <div className="col-lg-3 "></div>
        </ExamStructure>
      );
    }

    if (!loading && questions.length < 1) {
      return (
        <ExamStructure
          currentQuestionIndex={currentQuestionIndex}
          questions={questions}
        >
          <div className="col-lg-1 col-sm-1" />
          <div className="col-lg-10">
            <h1 className="header">Liver Module</h1>
            <h1> {error_msg}</h1>
          </div>
          <div className="col-md-3 "></div>
        </ExamStructure>
      );
    }

    return (
      <>
        <ExamStructure
          currentQuestionIndex={currentQuestionIndex}
          questions={questions}
          pauseExam={this.pauseExam}
          timer={timer}
        >
          {showCompleteImagePopup && (
            <Popup
              message="Are you sure you want to move to the Next Image? All submissions will be final for this image."
              type="exam"
              confirm={this.saveAnswer}
              cancel={this.closeCompleteImagePopup}
              dontShowAgain={this.dontShowcloseCompleteImagePopup}
            />
          )}
          {hasError && (
            <Popup message={error_msg} type="exam" cancel={this.hideAlert} />
          )}
          {showPauseExamPopup && (
            <Popup
              message="You can resume your test from the dashboard"
              type="exam"
              cancel={this.cancelPauseExams}
              confirm={this.goBackToDashboard}
              confirmTitle="Pause"
            />
          )}
          <div className="col-lg-1 col-sm-1" />
          <div className="col-lg-7 col-sm-11 answer-tablet">
            <h1 className="header">Liver Module</h1>
            <div className="question-description">
              <p>
                Select and draw the Region of Interest (ROI) for as many tissues
                as you can see in the image.
              </p>
              <p>Select “Nothing Detected” if no tissues are observed.</p>
            </div>
            <p className="pd-info">
              <span> Patient Information: </span>
              Fatty Liver{" "}
            </p>

            {/* <img className="liver-img" src={`${question.imageUrl}`} alt="" /> */}
            <div style={{ opacity: "0.9" }} className="canvas">
              <img
                src="/images/undo.svg"
                onClick={(e) => {
                  e.stopPropagation();
                  this.undoROI();
                }}
                alt="UNDO"
                className="undo-roi"
              />
              <p className="undo-text">Undo</p>
              <div
                onMouseDown={this.canvasClicked}
                onTouchStart={this.canvasClicked}
                className="to-make-it-div"
              >
                <CanvasDraw
                  onChange={this.mouseOverOnImage}
                  disabled={!allowLabeling}
                  ref={(canvasDraw) => (this.canvasRef = canvasDraw)}
                  brushColor="red"
                  catenaryColor="red"
                  imgSrc={`${currentQuestion.imageUrl}`}
                  brushRadius={0}
                  lazyRadius={0}
                  canvasWidth={canvasWidth}
                  canvasHeight={canvasHeight}
                  className="liver-img"
                  savedData={drawnTissueCord}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-sm-1" />
          <div className="col-lg-3 col-sm-11">
            <ExamSidebar
              isTissueImageGenerated={this.state.isTissueImageGenerated}
              tissues={currentQuestion.tissues}
              selectTissue={this.selectTissue}
              selectQuality={this.selectQuality}
              saveTissueCordinates={this.saveTissueCordinates}
              showDrawnTissue={this.showDrawnTissue}
              removeTissue={this.removeTissue}
              answerQuestion={this.answerQuestion}
              currentTissueSelected={currentTissueSelected}
              currentQuestionIndex={currentQuestionIndex}
              questions={questions}
            />
          </div>
        </ExamStructure>

        <div className="col-lg-12 col-sm-12">
          {currentQuestion.maskedDivs.map((t) => {
            return (
              <>
                <br />
                <div style={{ opacity: "1" }}>
                  <div
                    style={t.BWContainerStyleBig}
                    ref={(masked) => (this[`${t.tissue}Ref`] = masked)}
                  >
                    <img
                      src="/images/BW_Liver_Example.jpg"
                      style={t.imgStyles}
                      alt=""
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }
}
export default TestExamComponent;
