import React from "react";

import SidebarComponent from "~/Components/Sidebar";
import { TopBarComponent } from "./_TopBarComponent";

const AnswersStructure = (props) => {
  let {
    showPreviusAnswer,
    showNextAnswer,
    currentAnswerIndex,
    answers,
    history,
  } = props;
  return (
    <div className="exam exam-result">
      <SidebarComponent activeItem="tm" type="admin" />
      <section className="page">
        <TopBarComponent
          history={history}
          showPreviusAnswer={showPreviusAnswer}
          showNextAnswer={showNextAnswer}
          currentAnswerIndex={currentAnswerIndex}
          answers={answers}
        />
        <div className="row exam">
          <div className="col-md-1"></div>
          {props.children}
        </div>
      </section>
    </div>
  );
};

export default AnswersStructure;
