import React, { useState } from "react";
import numeral from "numeral";

import SidebarComponent from "~/Components/Sidebar";

import OverlayTutorial from "~/Components/OverlayTutorial";

const ExamStructure = (props) => {
  let { currentQuestionIndex, questions, timer, pauseExam } = props;
  const [showTutorial, toggleTutorial] = useState(false);
  const [showOverlayInfoBox, toggleOverlayInfoBox] = useState(true);
  const [userOpenedOverlay, userOpenedOverlayToogle] = useState(false);

  const openOverLay = () => {
    toggleTutorial(true);
    userOpenedOverlayToogle(true);
  };

  setTimeout(() => {
    toggleOverlayInfoBox(false);
  }, 1000 * 10);

  if (showTutorial) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }
  return (
    <div className="exam exam-liver">
      {showTutorial && <OverlayTutorial closeTutorial={toggleTutorial} />}
      <SidebarComponent type="dr" mini activeItem="exam" />
      <section className="page">
        <div className="row top-bar">
          <div className="col-lg-1 col-md-1 mt-auto " />
          <div className="col-lg-9 col-md-4 mt-auto">
            <div id="pause-element">
              <h3>
                <span onClick={pauseExam}>
                  Pause Labelling {"    "}
                  <img src="/images/pause.svg" className="pause" alt="pause" />
                </span>
                <span className="timer">
                  {" "}
                  {timer && numeral(timer).format("00:00:00")}
                </span>
              </h3>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 mt-auto  pl-4">
            <div className="right-info">
              <h2 className="counter">
                {currentQuestionIndex + 1}/{questions.length}
              </h2>
              <div className="overlay-actions">
                <p onClick={openOverLay} className="overlay-icon">
                  <img
                    src={
                      userOpenedOverlay
                        ? "/images/info.svg"
                        : "/images/info_red_i.svg"
                    }
                    className=""
                    alt="overlay-icon"
                  />
                </p>
                <div
                  className={`${
                    showOverlayInfoBox ? "overlay-info-box" : "hide-this"
                  }`}
                >
                  <div className="content">
                    <p className="title"> Tutorial :</p>
                    <p className="description">
                      Click the info card for a tutorial.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row exam">{props.children}</div>
      </section>
    </div>
  );
};

export default ExamStructure;
