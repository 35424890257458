import React from "react";
const DrawnTissuesList = (props) => {
  let { tissues, showDrawnTissue, removeTissue } = props;
  return (
    <ul>
      <li id="overlay-test-tissue" className="hide-this">
        <p>Liver</p>
        <span className="delete-tissue"> Delete </span>
      </li>
      {Object.keys(tissues).map((key, index) => (
        <li>
          <p onClick={showDrawnTissue.bind(this, key)}>
            {key.replace("_", " ")}
          </p>
          <span
            onClick={removeTissue.bind(this, key)}
            className="delete-tissue"
          >
            {" "}
            Delete{" "}
          </span>
          {/* <i onClick={removeTissue.bind(this, key)} className="fas fa-minus" /> */}
        </li>
      ))}
    </ul>
  );
};

export default DrawnTissuesList;
