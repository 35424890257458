import React from "react";
import axios from "axios";
// Components
import SidebarComponent from "~/Components/Sidebar";
import InfoBoxComponent from "~/Components/Boxes/InfoBoxComponent";
import BarChartComponent from "~/Components/Boxes/BarChartComponent";

import TableLoaderComponent from "~/Components/Loaders/_TableLoaderComponent";
import { baseUrl } from "~/Store/Apis/BaseUrl";

// Scss
import "~/styles/_dashboard.scss";
import store from "store2";

const options = {
  legend: "none",
};

class Dashboard extends React.Component {
  state = {
    loading: true,
    hasError: false,
    error_msg: null,
    total: 0,
    newDoctors: 0,
    inactive: 0,
    timeline: [
      ["M", "Day", { role: "style" }],
      ["Day", 0, "orange"],
    ],
    timelineCount: 0,
  };

  componentDidMount() {
    this.getDoctorsStats();
    this.getTimeline();
  }

  getDoctorsStats = () => {
    axios
      .get(`${baseUrl}/doctors/stats`)
      .then((res) => {
        console.log("respone is", res.data);
        const response = res.data;
        if (response.success === true) {
          this.setState({
            loading: false,
            total: response.data.total,
            newDoctors: response.data.newDoctors,
            inactive: response.data.inactive,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: response.message,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  getTimeline = () => {
    axios
      .get(`${baseUrl}/doctors/timeline?days=10`)
      .then((res) => {
        const response = res.data;
        if (response.success === true) {
          if (response.data.length) {
            let formatted_timeline = [["M", "Day", { role: "style" }]];
            let timeline = response.data;
            let timelineCount = 0;
            timeline.forEach((t) => {
              formatted_timeline.push([t["date"], t["count"], "orange"]);
              timelineCount += t["count"];
            });
            console.log("formatted time_line", formatted_timeline);
            this.setState({
              loading: false,
              timeline: formatted_timeline,
              timelineCount: timelineCount,
            });
          }
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: response.message,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  render() {
    const { loading, total, newDoctors, inactive, timeline, timelineCount } =
      this.state;

    let user = store.get("user");
    if (user.type !== "user") {
      this.props.history.push("/");
      window.location.reload();
    }

    let username = user.firstName || "";
    return (
      <div className="dashboard">
        <SidebarComponent activeItem="admin" type="admin" />

        <section className="content">
          <div className="row page-title">
            <div className="col-md-1" />
            <div className="col-md-8">
              <h1 className="header">Welcome, {username} !</h1>
            </div>
          </div>

          <div className="row stats">
            <div className="col-md-1" />
            <div className="col-md-10">
              <h1 className="title"> Overview</h1>
              {loading && <TableLoaderComponent />}
              {!loading && (
                <div className="row ">
                  <div className="col-sm-6 col-lg-4">
                    <InfoBoxComponent
                      title="New Doctors registered"
                      value={newDoctors}
                      link="/admin/doctors/new"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <InfoBoxComponent
                      title="Total registered"
                      value={total}
                      link="/admin/doctors"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <InfoBoxComponent
                      title="Inactive accounts"
                      value={inactive}
                      link="/admin/doctors/inactive"
                    />
                  </div>
                  <div className="col-sm-12 col-lg-7">
                    <BarChartComponent
                      title="Registered doctors"
                      value={timelineCount}
                      options={options}
                      data={timeline}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Dashboard;
