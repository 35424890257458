import React from "react";
import Select from "react-select";

const sortOptions = [
  { value: "firstName", label: "Name" },
  { value: "createdAt", label: "Register Date" },
  { value: "updatedAt", label: " Last Active" },
];

const TableHeaderActions = (props) => {
  let { loading, sortBy, filterByName } = props;
  const change = ({ value }) => {
    sortBy({ target: { name: "country", value } });
  };
  return (
    <div className="row table-action">
      <div className="col-md-1" />
      <div className="col-md-7">
        <div
          style={{ display: "flex" }}
          className={`${loading ? "hide-this" : ""}`}
        >
          <label className="sort-by-label">Sort By </label>
          {/* <i className="fas fa-caret-down" /> */}
          <Select
            options={sortOptions}
            onChange={change}
            classNamePrefix="customSelect"
            placeholder="Sort By"
          />
        </div>
      </div>
      <div className="col-md-3 ">
        <div style={{ display: "flex" }}>
          <label>Search</label>
          <input
            onChange={filterByName}
            type="text"
            className="form-control search-input"
            placeholder="Doctor Name"
          />
        </div>
      </div>
    </div>
  );
};
export default TableHeaderActions;
