import React from "react";
// Css
import "~/styles/_popup.scss";
// Types [exam, answer]
const OverlayTutorial = (props) => {
  let {
    message,
    confirm,
    cancel,
    dontShowAgain,
    confirmTitle,
    cancelTitle,
  } = props;
  return (
    <div id="popup">
      <div className="box">
        <p className="message">{message}</p>
        <div className="actions">
          {cancel && (
            <button
              onClick={cancel}
              type="button"
              className="btn btn-large btn-blue login"
            >
              {cancelTitle ? cancelTitle : "Go Back"}
            </button>
          )}

          {confirm && (
            <button
              onClick={confirm}
              type="button"
              className="btn btn-large btn-blue login"
            >
              {confirmTitle ? confirmTitle : "Complete"}
            </button>
          )}

          {dontShowAgain && (
            <div className="check">
              <input
                type="checkbox"
                id="checkbox_id"
                className="form-check-input"
                onChange={dontShowAgain}
              />
              <label className="form-check-label" htmlFor="checkbox_id">
                <h3>
                  Don’t show me this message again for the remainder of the
                  test.
                </h3>
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  // let { type, ...rest } = props;

  // return (
  //   <div id="popup">
  //     <Exam {...rest} />
  //   </div>
  // );
};
export default OverlayTutorial;
