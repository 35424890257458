import moment from "moment";

export const dateFormat = (timestamp) => {
  moment.updateLocale(moment.locale(), { invalidDate: "-" });
  return moment(timestamp).format("DD.MM.YYYY");
};

export const checkDate = (from, to, check) => {
  var fDate, lDate, cDate;
  fDate = Date.parse(from);
  lDate = Date.parse(to);
  cDate = Date.parse(check);

  if (cDate <= lDate && cDate >= fDate) {
    return true;
  }
  return false;
};

/*
 * check if given date is older by a week or mre
 */
export const isOlderThanWeekAgo = (date) => {
  let givenDate = moment(date);
  let diffOfDays = moment().diff(givenDate, "days");
  console.log("diffOfDays", diffOfDays);
  if (diffOfDays > 6) {
    return true;
  }
  return false;
};
