import React, { useState } from "react";
import store2 from "store2";

import TabletSidebar from "./_TabletSidebar";
import DrMiniSidebar from "./_DrMiniSidebar";
import DrSidebar from "./_DrSidebar";

import AdminMiniSidebar from "./_AdminMiniSidebar";
import AdminSidebar from "./_AdminSidebar";

import "~/styles/_sidebar.scss";

const logout = () => {
  store2.remove("token");
  window.location.reload();
};

const SidebarComponent = (props) => {
  let { type, mini, ...rest } = props;

  // widthType = ['mini','max']
  const navType = store2.get("navType") || "mini";
  const firstName = store2.get("firstName") || "";
  const lastName = store2.get("lastName") || "";

  const nameInitials = firstName[0] + lastName[0];

  const [widthType, changeWidthType] = useState(navType);
  const changeSidebarType = (type) => {
    store2.set("navType", type);
    changeWidthType(type);
  };
  return (
    <>
      <TabletSidebar logout={logout} type={type} {...rest} />
      {type === "dr" && widthType === "mini" && (
        <DrMiniSidebar
          changeWidthType={changeSidebarType}
          nameInitials={nameInitials}
          logout={logout}
          {...rest}
        />
      )}
      {type === "dr" && widthType !== "mini" && (
        <DrSidebar
          changeWidthType={changeSidebarType}
          lastName={lastName}
          logout={logout}
          {...rest}
        />
      )}
      {type === "admin" && widthType === "mini" && (
        <AdminMiniSidebar
          nameInitials={nameInitials}
          changeWidthType={changeSidebarType}
          logout={logout}
          {...rest}
        />
      )}
      {type === "admin" && widthType !== "mini" && (
        <AdminSidebar
          changeWidthType={changeSidebarType}
          logout={logout}
          {...rest}
        />
      )}
    </>
  );
};

export default SidebarComponent;
