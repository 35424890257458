import React from "react";
import Select from "react-select";

const specializationList = [
  { value: "Radiologist", label: "Radiologist" },
  { value: "Oncologists", label: "Oncologists" },
  { value: "Hepatologist", label: "Hepatologist" },
  { value: "Primary-care", label: "Primary Care" },
  { value: "Sonographer-Technician", label: "Sonographer/Technician" },
  { value: "Other", label: "Other" },
];
const experinceList = [
  { value: "1", label: "less than 1" },
  { value: "1_5", label: "1-5" },
  { value: "6_10", label: "6-10" },
  { value: "11_15", label: "11-15" },
  { value: "16_20", label: "16-20" },
  { value: "20+", label: "20 +" },
];

const StepTwoComponent = (props) => {
  const {
    errMsg,
    hasBackendError,
    backendErrorMsg,
    handleInputChange,
    goToStepThree,
    goToStepOne,
  } = props;
  return (
    <div class="login-form">
      <h1 class="title">Step 2: Experience</h1>
      <p class="body-copy">
        Please submit the following information based on your current experience
        level.
      </p>
      {hasBackendError && (
        <div class="alert alert-danger" role="alert">
          {backendErrorMsg}
        </div>
      )}
      <form action="" class="form-data">
        <div class="row row-register-2">
          <div class="col-md-6">
            <div class="form-group">
              <div class="input-options">
                <h2 for="firstname"> Institution</h2>
              </div>
              <input
                className={`form-control ${
                  errMsg.includes("institution") ? "red-label" : ""
                }`}
                name="institution"
                onChange={handleInputChange}
                type="text"
                placeholder="Enter institution name"
              />
              {errMsg.includes("institution") && (
                <span className="err-msg">Please enter your institution. </span>
              )}
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group group-right">
              <div class="input-options">
                <h2 for="specialization"> Specialization</h2>
              </div>
              <Select
                options={specializationList}
                onChange={({ value }) =>
                  handleInputChange({
                    target: { name: "specialization", value },
                  })
                }
                classNamePrefix="customSelect"
                className={` ${
                  errMsg.includes("specialization") ? "red-label" : ""
                }`}
              />
              {errMsg.includes("specialization") && (
                <span className="err-msg">
                  Please select a specialization.{" "}
                </span>
              )}
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="input-options">
                <h2 for="title"> Title</h2>
              </div>
              <input
                className={`form-control ${
                  errMsg.includes("title") ? "red-label" : ""
                }`}
                name="title"
                onChange={handleInputChange}
                type="text"
                placeholder="Enter title"
              />
              {errMsg.includes("title") && (
                <span className="err-msg">Please enter your title. </span>
              )}
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group group-right">
              <div class="input-options">
                <h2 for="experince"> Years of Experience</h2>
              </div>
              <Select
                options={experinceList}
                onChange={({ value }) =>
                  handleInputChange({ target: { name: "experince", value } })
                }
                classNamePrefix="customSelect"
                className={` ${
                  errMsg.includes("experince") ? "red-label" : ""
                }`}
              />
              {errMsg.includes("experince") && (
                <span className="err-msg">
                  Please select years of experience.{" "}
                </span>
              )}
            </div>
          </div>
        </div>
      </form>
      <hr class="auth-seprator" />
      <div>
        <button
          onClick={goToStepOne}
          type="button"
          class="btn btn-orange-border  btn-back"
        >
          Back to Step 1
        </button>
        <button
          onClick={goToStepThree}
          type="button"
          class="btn  btn-orange  btn-bottom login"
        >
          Step 3
        </button>
      </div>
    </div>
  );
};
export default StepTwoComponent;
