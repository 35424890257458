import React from "react";

const ResetPassowordForm = (props) => {
  let {
    handleInputChange,
    errMsg,
    hasBackendError,
    backendErrorMsg,
    submit,
  } = props;
  return (
    <>
      <h1 className="title">Reset Password</h1>
      <p className="body-copy">Enter your new password.</p>
      <form onSubmit={submit} className="form-data">
        <div className="row row-login">
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Password</h2>
              </div>
              <input
                className="form-control"
                name="password"
                type="password"
                onChange={handleInputChange}
                placeholder="Enter password"
              />
              {errMsg.includes("password") && (
                <span className="err-msg"> Please enter your password</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> Confirm Password</h2>
              </div>
              <input
                className="form-control"
                name="confirm_password"
                type="password"
                onChange={handleInputChange}
                placeholder="Re-Enter password"
              />
              {errMsg.includes("confirm_password") && (
                <span className="err-msg"> Please re-enter your password</span>
              )}
              {errMsg.includes("confirm_password_not_match") && (
                <span className="err-msg"> Password doesn't match</span>
              )}
              {hasBackendError && (
                <span className="err-msg">{backendErrorMsg}</span>
              )}
            </div>
          </div>
        </div>
        <hr className="auth-seprator" />
        <button
        onClick={submit}
        type="submit"
        className="btn  btn-orange  btn-bottom"  >
          Submit
        </button>
      </form>
    </>
  );
};

export default ResetPassowordForm;
