import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import store from "store2";
// Components
import SidebarComponent from "~/Components/Sidebar";
import InfoBoxComponent from "~/Components/Boxes/InfoBoxComponent";
import BarChartComponent from "~/Components/Boxes/BarChartComponent";
import TableLoaderComponent from "~/Components/Loaders/_TableLoaderComponent";

import { baseUrl } from "~/Store/Apis/BaseUrl";

// Scss
import "~/styles/_dashboard.scss";

// google chart
const data = [
  ["M", "Density", { role: "style" }],
  ["T", 8, "orange"], // RGB value
  ["W", 10, "orange"], // English color name
  ["T", 50, "orange"],
  ["F", 21, "color: orange"], // CSS-style declaration
  ["S", 19, "orange"],
  ["S", 15, "orange"],
];
const options = {
  // title: "Last Doctors",
  // hAxis: { title: "Age", viewWindow: { min: 0, max: 7 } },
  // vAxis: { title: "Weight", viewWindow: { min: 0, max: 25 } },
  legend: "none",
};

class Dashboard extends React.Component {
  state = {
    loading: true,
    hasError: false,
    error_msg: null,
    ongoing: 0,
    review: 0,
    complete: 0,
  };

  componentDidMount() {
    this.getTMStats();
  }

  getTMStats = () => {
    axios
      .get(`${baseUrl}/tm/stats`)
      .then((res) => {
        console.log("respone is", res.data);
        let response = res.data;
        if (response.success === true) {
          this.setState({
            loading: false,
            ongoing: response.ongoing,
            review: response.review,
            complete: response.complete,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  render() {
    const { loading, ongoing, review, complete } = this.state;
    let user = store.get("user");
    let username = user.firstName || "";

    return (
      <div className="dashboard">
        <SidebarComponent activeItem="tm" type="admin" />

        <section className="content">
          <div className="row page-title">
            <div className="col-md-1" />
            <div className="col-md-8">
              <h1 className="header">Welcome, {username} !</h1>
            </div>
          </div>

          <div className="row stats">
            <div className="col-md-1" />
            <div className="col-md-10">
              <h1 className="title"> Training Module</h1>
              <Link to="/admin/tm/all">View All</Link>
              {loading && <TableLoaderComponent />}
              {!loading && (
                <div className="row ">
                  <div className="col-sm-6 col-lg-4">
                    <InfoBoxComponent
                      title="ONGOING"
                      value={ongoing}
                      link="/admin/tm/ongoing"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <InfoBoxComponent
                      title="TO REVIEW"
                      value={review}
                      link="/admin/tm/review"
                    />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <InfoBoxComponent
                      title="COMPLETED"
                      value={complete}
                      link="/admin/tm/completed"
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <BarChartComponent
                      title="Training completed"
                      value={70}
                      options={options}
                      data={data}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Dashboard;
