import React from "react";
import { Link } from "react-router-dom";

const DrSidebar = (props) => {
  let { logout, activeItem, changeWidthType } = props;

  return (
    <section className="sidebar left-sidebar">
      <img
        src="/images/cheveron-circle-left.svg"
        className="cheveron-circle-left"
        onClick={() => changeWidthType("mini")}
        alt="toogle"
      />
      <ul className="menu">
        <li>
          <Link to="/admin" className="no-style">
            <img className="side-logo" src="/images/logo_full.png" alt="" />
          </Link>
        </li>
        <li className="text-center name">Admin</li>

        <li className={`${activeItem === "admin" ? "active" : ""}`}>
          <Link to="/admin" className="no-style">
            <i class="fa fa-th-large menu-icon "></i> Dashboard
          </Link>
        </li>
        {/* Training Module */}
        <li className={`${activeItem === "tm" ? "active" : ""}`}>
          <Link to="/admin/tm" className="no-style">
            <img
              src={`${
                activeItem === "tm" ? "/images/tm-white.svg" : "/images/tm.svg"
              }`}
              alt="right circle"
              className="svg-image menu-icon"
            />{" "}
            Training Module
          </Link>
        </li>
        {/* Cases */}
        <li className={`${activeItem === "liver" ? "active" : ""}`}>
          <Link to="/admin/liver" className="no-style">
            <img
              src={`${
                activeItem === "liver"
                  ? "/images/cases-white.svg"
                  : "/images/cases.svg"
              }`}
              alt="right circle"
              className="svg-image menu-icon"
            />{" "}
            Cases
          </Link>
        </li>
        <li></li>
        <li onClick={logout} className="text-center logout">
          Sign Out
        </li>
      </ul>
    </section>
  );
};
export default DrSidebar;
