import React from "react";

const LoginCodeComponent = (props) => {
  let { enterLoignCode, hasError, handleInputChange, errMsg } = props;
  return (
    <div className="login-form">
      <h1 className="title">Enter One Time Password</h1>
      <p className="body-copy">
        A One Time Password has been sent to the email associated with this
        account. Please enter it here.{" "}
      </p>

      <form onSubmit={enterLoignCode} className="form-data">
        <div className="row row-login">
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-options">
                <h2 htmlFor="email"> One Time Password</h2>
              </div>
              <input
                className={`form-control ${hasError ? "red-label" : ""}`}
                name="loginCode"
                onChange={handleInputChange}
                placeholder="Enter here"
              />
              {hasError && <span className="err-msg">{errMsg}</span>}
            </div>
          </div>
        </div>
        <hr className="auth-seprator" />
        <button type="submit" className="btn btn-large btn-orange login">
          Submit
        </button>
      </form>
    </div>
  );
};

export default LoginCodeComponent;
