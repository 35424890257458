import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";

import NotFoundComponent from "~/Components/NotFoundComponent";

// Auth Routes
import Login from "~/Pages/Auth/Login";
import Register from "~/Pages/Auth/Register";
import ForgetPassword from "~/Pages/Auth/ForgetPassword";
import ResetPassword from "~/Pages/Auth/ResetPassword";
import Verify from "~/Pages/Auth/Verify";

import EditProfile from "~/Pages/EditProfile";
import Dashboard from "~/Pages/Admin/Dashboard";

// Doctors
import AllDoctors from "~/Pages/Admin/Doctors/All";
import InactiveDoctors from "~/Pages/Admin/Doctors/Inactive";
import NewDoctors from "~/Pages/Admin/Doctors/New";
import DoctorProfile from "~/Pages/Admin/Doctors/Profile";

// TM
import TrainingModuleDashboard from "~/Pages/Admin/TrainingModule/Dashboard";
import AllTrainingModule from "~/Pages/Admin/TrainingModule/All";
import OngoingTM from "~/Pages/Admin/TrainingModule/Ongoing";
import ReviewTM from "~/Pages/Admin/TrainingModule/Review";
import CompletedTM from "~/Pages/Admin/TrainingModule/Completed";
import Answers from "~/Pages/Admin/TrainingModule/Answers";

// Liver
import LiverDashboard from "~/Pages/Admin/Liver/Dashboard";
import AllLiverModule from "~/Pages/Admin/Liver/All";
import OngoingLiverModule from "~/Pages/Admin/Liver/Ongoing";
import CompletedLiverModule from "~/Pages/Admin/Liver/Completed";
import LiverAnswers from "~/Pages/Admin/Liver/Answers";

// Dorcor Exam
import DoctorDashboard from "~/Pages/DoctorDashboard";
import LiverExam from "~/Pages/LiverExam";
import Exam from "~/Pages/Exam";

const AppRouter = () => {
  return (
    <Switch>
      {/*  Auth Routes*/}
      <AuthRoute exact path="/login" component={Login} />
      <AuthRoute exact path="/register" component={Register} />
      <AuthRoute exact path="/forget-password" component={ForgetPassword} />
      <AuthRoute
        exact
        path="/reset-password/:forgetPasswordToken"
        component={ResetPassword}
      />
      <AuthRoute exact path="/verify/:token" component={Verify} />
      {/* Doctor Side */}
      <PrivateRoute exact path="/" component={DoctorDashboard} />
      <PrivateRoute exact path="/profile" component={EditProfile} />
      <PrivateRoute exact path="/tm-exam" component={Exam} />
      <PrivateRoute exact path="/liver-exam" component={LiverExam} />
      {/* Admin Side */}
      <Route exact path="/admin" component={Dashboard} />
      <Route exact path="/admin/doctors" component={AllDoctors} />
      <Route exact path="/admin/doctors/inactive" component={InactiveDoctors} />
      <Route exact path="/admin/doctors/new" component={NewDoctors} />
      <Route exact path="/admin/tm" component={TrainingModuleDashboard} />
      <Route exact path="/admin/tm/all" component={AllTrainingModule} />
      <Route exact path="/admin/tm/ongoing" component={OngoingTM} />
      <Route exact path="/admin/tm/review" component={ReviewTM} />
      <Route exact path="/admin/tm/completed" component={CompletedTM} />
      <Route exact path="/admin/tm/:doctorId/answers" component={Answers} />
      <Route exact path="/admin/liver" component={LiverDashboard} />
      <Route exact path="/admin/liver/all" component={AllLiverModule} />
      <Route exact path="/admin/liver/ongoing" component={OngoingLiverModule} />
      <Route
        exact
        path="/admin/liver/completed"
        component={CompletedLiverModule}
      />
      <Route
        exact
        path="/admin/liver/:caseId/:userId/answers"
        component={LiverAnswers}
      />
      <Route exact path="/admin/doctors/:doctorId" component={DoctorProfile} />
      <Route component={NotFoundComponent} />
    </Switch>
  );
};

export default AppRouter;
