import React from "react";
import { Link } from "react-router-dom";

const DrSidebar = (props) => {
  let { logout, activeItem, changeWidthType, lastName } = props;
  return (
    <section className="sidebar left-sidebar">
      <img
        src="/images/cheveron-circle-left.svg"
        className="cheveron-circle-left"
        onClick={() => changeWidthType("mini")}
        alt="toogle"
      />
      <ul className="menu">
        <li>
          <Link to="/" className="no-style">
            <img className=" side-logo" src="/images/logo_full.png" alt="" />
          </Link>
          <hr />
        </li>

        <li className="text-center bold">Dr {lastName}</li>
        <li className={`${activeItem === "exam" ? "active" : ""}`}>
          <Link to="/exam" className="no-style">
            <i className="fa fa-th-large menu-icon"></i> Training Module
          </Link>
        </li>
        <Link to="/profile" className="no-style">
          <li className={`${activeItem === "profile" ? "active" : ""} pointer`}>
            <i className="fa fa-user menu-icon "></i> Profile
          </li>
        </Link>
        <li></li>
        <li onClick={logout} className="text-center logout">
          Sign Out
        </li>
      </ul>
    </section>
  );
};
export default DrSidebar;
