import React from "react";

import axios from "axios";
import store2 from "store2";
import CanvasDraw from "react-canvas-draw";
import SweetAlert from "react-bootstrap-sweetalert";
import Popup from "~/Components/Popup";

// Components
import ExamLoaderComponent from "~/Components/Loaders/_ExamLoaderComponent";

import { AnswerSideBar } from "./_AnswerSidebar";
import { AnswerTimeInfo } from "./_AnswerTimeInfo";

import AnswersStructure from "./AnswersStructure";

// helpers
// import "~/styles/Admin/TrainingModule/_AnswersPage.scss";
import "~/styles/_exam.scss";
import { baseUrl } from "~/Store/Apis/BaseUrl";

// 13 Inch
let canvasWidth = 700;
let canvasHeight = 462;

const windowWidth = window.innerWidth;

// Ipad
if (windowWidth < 800) {
  console.log("SMALLERR!!");
  canvasWidth = 600;
  canvasHeight = 395;
}
// 15 Inch
if (windowWidth > 1600) {
  canvasWidth = 900;
  canvasHeight = 594;
}

console.log("Width is", windowWidth, canvasWidth);

const JWT_TOKEN = store2.get("token");

class DoctorAnswersComponent extends React.Component {
  questionsAnswers = [];
  state = {
    preview_type: "grading", // ['grading','viewing']
    showCompletionPopUp: false,
    loading: true,
    nextAnswerLoader: false,
    answers: [],
    hasError: false,
    error_msg: null,
    currentAnswerIndex: 0,
    currentAnswer: null,
    drawnTissueCord: null,
    inputErrorMsg: {},
    form: {
      liverGrade: "",
      minorTissueGrade: "",
      majorTissueGrade: "",
    },
  };

  constructor(props) {
    super(props);
    this.doctorId = props.match.params.doctorId;
  }

  componentDidMount() {
    this.getDoctorAnswers();
  }

  getDoctorAnswers = () => {
    axios
      .get(`${baseUrl}/doctors/${this.doctorId}/answers`)
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            const answers = res.data.data;
            // check if has already answers
            let new_answers = answers;
            let preview_type; // ['grading','viewing']

            if (answers[0].liverGrade) {
              preview_type = "viewing";
            } else {
              preview_type = "grading";
              // new_answers = answers.map((a, i) => {
              //   a.liverGrade = "";
              //   a.minorTissueGrade = "";
              //   a.majorTissueGrade = "";
              //   return a;
              // });
            }
            this.setState({
              preview_type,
              loading: false,
              answers: new_answers,
              currentAnswer: new_answers[0],
            });
          } else {
            this.setState({
              loading: false,
              hasError: true,
              error_msg: "No More Questions ",
              answers: [],
              currentAnswer: {},
            });
          }
        } else {
          store2.remove("token");
          window.location.reload();
        }
      })
      .catch((err) => {
        return err;
      });
  };

  handleInputChange = (event) => {
    const form = { ...this.state.form };
    let value = event.target.value;
    if (value >= 100) {
      return;
    }
    if (value < 0) {
      return;
    }
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  showDrawnTissue = (tissueName) => {
    this.canvasRef.clear();
    const currentAnswer = this.state.currentAnswer;
    const tissueCord = currentAnswer.tissues[tissueName];
    this.canvasRef.loadSaveData(JSON.stringify(tissueCord));
    this.setState({
      drawnTissueCord: tissueCord,
    });
  };

  showNextAnswer = (currentAnswerIndex) => {
    let answers = this.state.answers;

    if (currentAnswerIndex + 1 === answers.length) {
      return;
    }
    // check if next answser has grades
    let nextAnswer = answers[currentAnswerIndex + 1];
    if (!nextAnswer.liverGrade) {
      return;
    }
    this.setState({
      nextAnswerLoader: true,
    });

    this.setState({
      currentAnswer: nextAnswer,
      currentAnswerIndex: currentAnswerIndex + 1,
      form: {},
    });
    setTimeout(() => {
      this.setState({
        nextAnswerLoader: false,
      });
    }, 500);
  };

  showPreviusAnswer = (currentAnswerIndex) => {
    this.setState({
      nextAnswerLoader: true,
    });

    let answers = this.state.answers;
    if (currentAnswerIndex === 0 || currentAnswerIndex - 1 === answers.length) {
      return;
    }
    this.setState({
      currentAnswer: answers[currentAnswerIndex - 1],
      currentAnswerIndex: currentAnswerIndex - 1,
    });
    setTimeout(() => {
      this.setState({
        nextAnswerLoader: false,
      });
    }, 500);
  };

  validateGrades = () => {
    const { liverGrade, minorTissueGrade, majorTissueGrade } = {
      ...this.state.form,
    };
    let errMsg = {};
    if (!liverGrade) {
      errMsg.liverGrade = "liver grade is required";
    } else {
      if (liverGrade < 0) {
        errMsg.liverGrade = "liver can't be -ve";
      }
      if (liverGrade > 100) {
        errMsg.liverGrade = "liver can't be more than 100";
      }
    }
    if (!minorTissueGrade) {
      errMsg.minorTissueGrade = "minor tissue grade grade is required";
    } else {
      if (minorTissueGrade < 0) {
        errMsg.minorTissueGrade = "minor tissue grade can't be -ve";
      }
      if (minorTissueGrade > 100) {
        errMsg.minorTissueGrade = "minor tissue grade can't be more than 100";
      }
    }
    if (!majorTissueGrade) {
      errMsg.majorTissueGrade = "major tissue grade grade is required";
    } else {
      if (majorTissueGrade < 0) {
        errMsg.majorTissueGrade = "major tissue grade can't be -ve";
      }
      if (majorTissueGrade > 100) {
        errMsg.majorTissueGrade = "major tissue grade can't be more than 100";
      }
    }

    if (Object.keys(errMsg).length > 0) {
      this.setState({
        inputErrorMsg: errMsg,
      });
      return false;
    }
    return true;
  };

  completeImage = () => {
    // validate
    if (!this.validateGrades()) {
      return;
    }
    const { liverGrade, minorTissueGrade, majorTissueGrade } = {
      ...this.state.form,
    };
    const { currentAnswer, currentAnswerIndex, answers } = this.state;

    // add tissues grades to answers state
    currentAnswer.liverGrade = liverGrade;
    currentAnswer.minorTissueGrade = minorTissueGrade;
    currentAnswer.majorTissueGrade = majorTissueGrade;
    answers[currentAnswerIndex] = currentAnswer;

    if (currentAnswerIndex + 1 === answers.length) {
      this.setState({
        answers,
      });
      this.setState({
        showCompletionPopUp: true,
      });
      return;
    }
    this.setState({
      nextAnswerLoader: true,
      inputErrorMsg: {},
      currentAnswer: answers[currentAnswerIndex + 1],
      answers: answers,
      currentAnswerIndex: currentAnswerIndex + 1,
      form: {},
    });
    setTimeout(() => {
      this.setState({
        nextAnswerLoader: false,
      });
    }, 500);
  };

  saveGrades = () => {
    let { answers } = this.state;
    let filtered_answers = answers.map((answer) => {
      let {
        _id,
        questionId,
        userId,
        liverGrade,
        minorTissueGrade,
        majorTissueGrade,
      } = answer;
      return {
        _id,
        questionId,
        userId,
        liverGrade,
        minorTissueGrade,
        majorTissueGrade,
      };
    });
    axios
      .post(
        `${baseUrl}/tm/grades`,
        {
          answers: filtered_answers,
          classification: "freeHand",
          doctorId: filtered_answers[0].userId,
        },
        {
          headers: {
            token: JWT_TOKEN,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          console.log("SUCESS,", res.data);
          this.props.history.push("/admin/tm");
        } else {
          this.setState({
            hasError: true,
            error_msg: [res.data.message],
          });
          console.log("errpr", res);
        }
      })
      .catch((err) => {
        return err;
      });
    return;
  };

  hideAlert = () => {
    this.setState({
      hasError: false,
      error_msg: null,
    });
  };

  render() {
    const {
      preview_type,
      showCompletionPopUp,
      loading,
      answers,
      currentAnswerIndex,
      currentAnswer,
      nextAnswerLoader,
      hasError,
      error_msg,
      drawnTissueCord,
      inputErrorMsg,
      form,
    } = this.state;

    if (loading || nextAnswerLoader) {
      return (
        <AnswersStructure
          history={this.props.history}
          showPreviusAnswer={this.showPreviusAnswer}
          showNextAnswer={this.showNextAnswer}
          currentAnswerIndex={currentAnswerIndex}
          answers={answers}
        >
          <div className="col-lg-10">
            <h1 className="header">Training Module Assesment</h1>
            <ExamLoaderComponent />
          </div>
        </AnswersStructure>
      );
    }

    if (!loading && answers.length < 1) {
      return (
        <AnswersStructure
          history={this.props.history}
          showPreviusAnswer={this.showPreviusAnswer}
          showNextAnswer={this.showNextAnswer}
          currentAnswerIndex={currentAnswerIndex}
          answers={answers}
        >
          <div className="col-lg-8">
            <h1 className="header">Training Module Assesment</h1>
            <h1> {error_msg}</h1>
          </div>
        </AnswersStructure>
      );
    }

    return (
      <AnswersStructure
        history={this.props.history}
        showPreviusAnswer={this.showPreviusAnswer}
        showNextAnswer={this.showNextAnswer}
        currentAnswerIndex={currentAnswerIndex}
        answers={answers}
      >
        <>
          <div className="col-lg-8 answer-tablet">
            <h1 className="header">Training Module Assesment</h1>
            <AnswerTimeInfo currentAnswer={currentAnswer} />
            {showCompletionPopUp && (
              <Popup
                message="Complete Final Review? The Training Module can be edited at any time from the Dashboard."
                type="exam"
                confirm={this.saveGrades}
                cancel={() => {
                  this.setState({ showCompletionPopUp: false });
                }}
                confirmTitle="Complete"
                cancelTitle="Go Back"
              />
            )}
            {hasError && (
              <SweetAlert title={error_msg} onConfirm={this.hideAlert} />
            )}

            {/* <img className="liver-img" src="./images/liver.jpg" alt="" /> */}
            {nextAnswerLoader && <ExamLoaderComponent />}
            <div style={{ opacity: "0.9" }}>
              <CanvasDraw
                onChange={this.mouseOverOnImage}
                disabled={true}
                ref={(canvasDraw) => (this.canvasRef = canvasDraw)}
                brushColor="red"
                catenaryColor="red"
                imgSrc={`${currentAnswer.imageUrl}`}
                brushRadius={0}
                lazyRadius={0}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                className="liver-img"
                savedData={drawnTissueCord}
              />
            </div>
          </div>
          <div className="col-lg-3  col-sm-12">
            <AnswerSideBar
              preview_type={preview_type}
              showNextAnswer={this.showNextAnswer}
              handleInputChange={this.handleInputChange}
              showDrawnTissue={this.showDrawnTissue}
              completeImage={this.completeImage}
              currentAnswer={currentAnswer}
              answers={answers}
              form={form}
              inputErrorMsg={inputErrorMsg}
              currentAnswerIndex={currentAnswerIndex}
            />
          </div>
        </>
      </AnswersStructure>
    );
  }
}
export default DoctorAnswersComponent;
