import React, { useState } from "react";
import { Link } from "react-router-dom";
import humanize from "humanize-duration";
import Select from "react-select";

import { dateFormat } from "~/Helpers/dateHelper";

const sortOptions = [
  { value: "free_hand", label: "Free Hand" },
  { value: "random_box", label: "Random Box" },
  { value: "na", label: "N/A" },
];

const toogleRowExtraInfo = (index) => {
  const extraRow = document.getElementById(`extra-info-${index}`);
  const classList = extraRow.classList;
  const toogleExtraInfoBtn = document.getElementById(
    `toogle-row-extra-info-${index}`
  );
  if (classList.contains("hide-this")) {
    toogleExtraInfoBtn.innerHTML = "-";
    extraRow.classList.remove("hide-this");
    extraRow.classList.add("m-fadeIn");
  } else {
    toogleExtraInfoBtn.innerHTML = "+";
    extraRow.classList.add("hide-this");
  }
};

const toogleAllRows = (noOfRows) => {
  for (let index = 0; index < noOfRows; index++) {
    toogleRowExtraInfo(index);
  }
};

let rowOptions = {};
const DoctorsTable = (props) => {
  let {
    status,
    fieldNamesMapper,
    sortColoumn,
    loading,
    doctors,
    changeClass,
  } = props;
  const [editableRows, updateEditableRows] = useState([]);

  const editRow = (rowId) => {
    let editableRowsCopy = [...editableRows];
    editableRowsCopy.push(rowId);
    updateEditableRows(editableRowsCopy);
  };

  const update = (rowId) => {
    // remove from toogle
    let editableRowsCopy = [...editableRows];
    if (editableRowsCopy.includes(rowId)) {
      editableRowsCopy = editableRowsCopy.filter((v) => v !== rowId);
      updateEditableRows(editableRowsCopy);
      // send data to parent component
      if (rowOptions[rowId]) {
        let { options, userId } = rowOptions[rowId];
        changeClass(options, userId);
      }
    }
  };

  const classificationSelectHandler = (rowId, options, userId) => {
    rowOptions[rowId] = {
      options,
      userId,
    };
  };

  return (
    <div className="table-overflow">
      <table className={`${loading ? "hide-this" : ""}`}>
        <tr id="t01">
          <th
            class="plusheader"
            onClick={toogleAllRows.bind(this, doctors.length)}
          >
            +
          </th>
          <th>
            NAME{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.name)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            START DATE{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.date)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            TIME{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.time)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>
            GRADE{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.grade)}
              class="fas fa-sort"
            ></i>
          </th>
          <th>CLASS</th>
          <th>
            STATUS{" "}
            <i
              onClick={sortColoumn.bind(this, fieldNamesMapper.status)}
              class="fas fa-sort"
            ></i>
          </th>
          {status === "completed" && <th>EDIT </th>}
        </tr>
        {doctors.map((tm, index) => {
          return (
            <>
              <tr className={index % 2 === 0 ? "" : "greyBG"}>
                <td
                  class="plus"
                  onClick={toogleRowExtraInfo.bind(this, index)}
                  id={`toogle-row-extra-info-${index}`}
                >
                  +
                </td>
                <td class="namelink">
                  <Link to={`/admin/doctors/${tm.userId}`}>
                    {`${tm.user[0] ? tm.user[0].firstName : ""} ${
                      tm.user[0] ? tm.user[0].lastName : ""
                    }`}
                  </Link>
                </td>
                <td>
                  {dateFormat(tm.user[0] ? tm.user[0].examStartDate : "")}
                </td>
                <td>
                  {humanize(tm.user[0].examTime * 1000, {
                    units: ["h"],
                    round: true,
                  })}
                </td>
                <td>{Math.round(tm.totalGrade * 100) / 100}%</td>
                {editableRows.includes(tm._id) ? (
                  <td>
                    <Select
                      options={sortOptions}
                      onChange={(option) =>
                        classificationSelectHandler(tm._id, option, tm.userId)
                      }
                      classNamePrefix="customSelect"
                    />
                  </td>
                ) : (
                  <td>
                    {tm.class
                      ? tm.class
                          .replace("_", " ")
                          .replace("freehand", "free hand") || "N/A"
                      : ""}
                  </td>
                )}

                <td>
                  {tm.status === "review" ? (
                    <Link to={`/admin/tm/${tm.userId}/answers`}>
                      <button className="btn btn-green btn-review">
                        Review
                      </button>
                    </Link>
                  ) : (
                    <>{tm.status}</>
                  )}
                </td>

                {!editableRows.includes(tm._id) && status === "completed" && (
                  <td onClick={() => editRow(tm._id)}>
                    <i class="fas fa-pen"></i>
                  </td>
                )}
                {editableRows.includes(tm._id) && status === "completed" && (
                  <td onClick={() => update(tm._id)}>
                    <img src="/images/download.svg" alt="save" />
                    {/* <i class="fas fa-pen"></i> */}
                  </td>
                )}
              </tr>
              <tr
                className={`${index % 2 === 0 ? "" : "greyBG"}  hide-this`}
                id={`extra-info-${index}`}
              >
                <td colspan="8">
                  <div className="row">
                    <div className="col-md-4">
                      {tm.status === "review" ||
                        (tm.status === "completed" && (
                          <Link to={`/admin/tm/${tm.userId}/answers`}>
                            <button className="btn btn-green btn-extra-info">
                              Review test
                            </button>
                          </Link>
                        ))}
                      {/* <button className="btn btn-green btn-extra-info">
                      Review test
                    </button> */}
                    </div>
                    <div className="col-md-2 left-align">
                      <h2>Region</h2>
                      <p>{tm.user[0].country}</p>
                    </div>
                    <div className="col-md-3 left-align">
                      <h2>Grades</h2>
                      <p>Liver: {Math.round(tm.liverGrade * 100) / 100}%</p>
                      <p>
                        Minor Tissue:{" "}
                        {Math.round(tm.minorTissueGrade * 100) / 100}%
                      </p>
                      <p>
                        Major Tissue:{" "}
                        {Math.round(tm.majorTissueGrade * 100) / 100}
                      </p>
                    </div>
                    <div className="col-md-3 left-align">
                      <h2>Time</h2>
                      <p>
                        Time:{" "}
                        {humanize(tm.user[0].examTime * 1000, {
                          units: ["m"],
                          round: true,
                        })}
                      </p>
                      <p>
                        Time Active:{" "}
                        {humanize(
                          (tm.user[0].examTime - tm.user[0].timeIdle) * 1000,
                          {
                            units: ["m"],
                            round: true,
                          }
                        )}
                      </p>
                      <p>
                        Time Idle:{" "}
                        {humanize(tm.user[0].timeIdle * 1000, {
                          units: ["m"],
                          round: true,
                        })}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          );
        })}
      </table>
    </div>
  );
};

export default DoctorsTable;
