import React from "react";

import { dateFormat } from "~/Helpers/dateHelper";
import humanize from "humanize-duration";

const ProfileTrainingCasesComponent = (props) => {
  let { loading, answers, toogleRowExtraInfo } = props;
  return (
    <table className={`${loading ? ".hide-this" : ""}`}>
      <tr id="t01">
        <th class="plusheader">+</th>
        <th>Training Module</th>
        <th>START DATE</th>
        <th>TIME</th>
        <th>Liver %</th>
        <th>Minor tissue % </th>
        <th>major Tissue %</th>
      </tr>
      {answers.map((answer, index) => {
        return (
          <>
            <tr className={index % 2 === 0 ? "" : "greyBG"}>
              <td
                class="plus"
                onClick={toogleRowExtraInfo.bind(this, index)}
                id={`toogle-row-extra-info-${index}`}
              >
                +
              </td>
              <td class="namelink">Image {index + 1}</td>
              <td>{dateFormat(answer.createdAt)}</td>
              <td>
                {humanize(answer.duration * 1000, {
                  units: ["m"],
                  round: true,
                })}
              </td>
              <td>{answer.liverGrade ? `${answer.liverGrade} %` : "-"}</td>
              <td>
                {answer.minorTissueGrade ? `${answer.minorTissueGrade} %` : "-"}
              </td>
              <td>
                {answer.majorTissueGrade ? `${answer.majorTissueGrade} %` : "-"}
              </td>
            </tr>
          </>
        );
      })}
    </table>
  );
};

export default ProfileTrainingCasesComponent;
