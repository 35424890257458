import React, { useState } from "react";
import StepOne from "./_StepOne";
import StepTwo from "./_StepTwo";
import StepFour from "./_StepFour";
import StepThree from "./_StepThree";
import StepFive from "./_StepFive";
import StepSix from "./_StepSix";
import "~/styles/_overlayTutorial.scss";

const MAX_STEPS = 6;
const OverlayTutorial = (props) => {
  let { closeTutorial } = props;
  const [step, changeStep] = useState(1);

  return (
    <div id="overlay">
      <div className="title">
        <h1> TUTORIAL</h1>
      </div>
      <div className="close">
        <img
          src="/images/overlay/X.svg"
          alt="Close"
          onClick={() => closeTutorial(false)}
        />
      </div>
      {step === 1 && <StepOne />}
      {step === 2 && <StepTwo />}
      {step === 3 && <StepThree />}
      {step === 4 && <StepFour />}
      {step === 5 && <StepFive />}
      {step === 6 && <StepSix />}

      <div className="steps">
        {step > 1 && (
          <>
            <i
              class="fas fa-long-arrow-alt-left __fa-caret-left left-arrow"
              onClick={() => changeStep(step - 1)}
            ></i>
            <i
              class="fa fa-arrow-up arrow-up"
              onClick={() => changeStep(step - 1)}
            ></i>
          </>
        )}

        <ul>
          <li
            onClick={() => changeStep(1)}
            className={`numberCircle ${step >= 1 ? "numberCircle-active" : ""}`}
          ></li>
          <li
            onClick={() => changeStep(2)}
            className={` numberCircle ${
              step >= 2 ? "numberCircle-active" : ""
            }`}
          ></li>
          <li
            onClick={() => changeStep(3)}
            className={` numberCircle ${
              step >= 3 ? "numberCircle-active" : ""
            }`}
          ></li>
          <li
            onClick={() => changeStep(4)}
            className={` numberCircle ${
              step >= 4 ? "numberCircle-active" : ""
            }`}
          ></li>
          <li
            onClick={() => changeStep(5)}
            className={` numberCircle ${
              step >= 5 ? "numberCircle-active" : ""
            }`}
          ></li>

          <li
            onClick={() => changeStep(6)}
            className={` numberCircle ${
              step >= 6 ? "numberCircle-active" : ""
            }`}
          ></li>
        </ul>
        {step < MAX_STEPS && (
          <>
            <i
              class="fas fa-long-arrow-alt-right __fa-caret-right right-arrow"
              onClick={() => changeStep(step + 1)}
            ></i>
            <i
              class="fa fa-arrow-down arrow-down "
              onClick={() => changeStep(step + 1)}
            ></i>
          </>
        )}
      </div>
    </div>
  );
};
export default OverlayTutorial;
