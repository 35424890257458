import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "~/Store/Apis/BaseUrl";
// Components
import StepCircleComponent from "./StepsCircleComponent";
import RegisterSteps from "./RegisterSteps";

import "~/styles/_auth.scss";

class RegisterComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      hasError: false,
      hasBackendError: false,
      backendErrorMsg: "",
      loginCode: null,
      showSuccessMessage: false,
      errMsg: [],
      form: {
        firstName: "",
        lastName: "",
        country: "",
        email: "",
        password: "",
        confirm_password: "",
        institution: "",
        specialization: "",
        title: "",
        experince: "",
      },
    };
  }

  componentDidMount() {
    document.title = "Oncoustics | Register";
  }

  handleInputChange = (event) => {
    console.log("INPUT", event.target.name, event.target.value);
    const form = { ...this.state.form };
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  resendVerificationMail = () => {
    axios
      .post(`${baseUrl}/auth/register/doctors`, this.state.form)
      .then((response) => {
        console.log("rsponse", response.data);
        let { success, message } = response.data;
        if (success) {
          this.setState({
            hasError: false,
            errMsg: "",
            hasBackendError: false,
            backendErrorMsg: "",
            step: 3,
          });
        } else {
          this.setState({
            hasError: false,
            errMsg: "",
            hasBackendError: true,
            backendErrorMsg: message,
          });
        }
      })
      .catch((err) => {
        this.setState({
          hasBackendError: true,
          backendErrorMsg: "Network Error",
        });
      });
    console.log("Heey");
  };

  register = () => {
    axios
      .post(`${baseUrl}/auth/register`, this.state.form)
      .then((response) => {
        console.log("rsponse", response.data);
        let { success, message } = response.data;
        if (success) {
          this.setState({
            hasError: false,
            errMsg: "",
            hasBackendError: false,
            backendErrorMsg: "",
            step: 3,
          });
        } else {
          this.setState({
            hasError: false,
            errMsg: "",
            hasBackendError: true,
            backendErrorMsg: message,
          });
        }
      })
      .catch((err) => {
        this.setState({
          hasBackendError: true,
          backendErrorMsg: "Network Error",
        });
      });
    console.log("Heey");
  };

  goToStepOne = () => {
    this.setState({ step: 1 });
  };

  validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  goToStepTwo = () => {
    const {
      firstName,
      lastName,
      email,
      country,
      password,
      confirm_password,
    } = { ...this.state.form };
    let errMsg = [];
    if (!firstName && firstName.length < 1) {
      errMsg.push("firstName");
    }
    if (!lastName && lastName.length < 1) {
      errMsg.push("lastName");
    }
    if (!email && email.length < 1) {
      errMsg.push("email");
    }
    if (!this.validateEmail(email)) {
      errMsg.push("not_valid_email");
    }
    if (!country && country.length < 1) {
      errMsg.push("country");
    }

    if (!password && password.length < 1) {
      errMsg.push("password");
    }

    if (password.length < 8) {
      errMsg.push("password");
    }
    // check if has capital case
    function hasNumber(myString) {
      return /\d/.test(myString);
    }

    function hasUpperCase(str) {
      return /[A-Z]/.test(str);
    }
    if (!hasNumber(password)) {
      errMsg.push("password");
    }
    if (!hasUpperCase(password)) {
      errMsg.push("password");
    }

    if (!confirm_password && confirm_password.length < 1) {
      errMsg.push("confirm_password");
    }

    if (confirm_password !== password) {
      errMsg.push("confirm_password");
    }
    if (errMsg.length > 0) {
      this.setState({
        step: 1,
        errMsg,
        hasError: true,
      });
    } else {
      this.checkEmailUnique();
    }
  };

  checkEmailUnique = () => {
    const { email, errMsg } = this.state.form;
    axios
      .post(`${baseUrl}/auth/check_email`, { email })
      .then((response) => {
        let { uniqueEmail } = response.data;
        console.log("rsponse", response.data);
        if (uniqueEmail) {
          this.setState({
            step: 2,
            errMsg: [],
            hasError: false,
          });
        } else {
          console.log("errrs", errMsg);
          this.setState({
            step: 1,
            errMsg: ["unique_email"],
            hasError: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          hasBackendError: true,
        });
      });
  };

  goToStepThree = () => {
    const { institution, specialization, title, experince } = {
      ...this.state.form,
    };
    let errMsg = [];
    if (!institution && institution.length < 1) {
      errMsg.push("institution");
    }
    if (!specialization && specialization.length < 1) {
      errMsg.push("specialization");
    }
    if (!title && title.length < 1) {
      errMsg.push("title");
    }
    if (!experince && experince.length < 1) {
      errMsg.push("experince");
    }
    if (errMsg.length > 0) {
      this.setState({
        errMsg,
        hasError: true,
      });
    } else {
      this.register();
    }
  };

  render() {
    const {
      step,
      hasError,
      errMsg,
      backendErrorMsg,
      hasBackendError,
    } = this.state;
    return (
      <section page="">
        <div className="row page">
          <div className="col-lg-3  col-md-12 left-sidebar">
            <div id="particles-js" />
          </div>
          <div className="col-md-12 top-sidebar"></div>

          <div className="col-md-1 col-md-0"></div>
          <div className="col-lg-7 col-md-9  main-section">
            <ul className="header-menu">
              <li>
                <Link to="/login">
                  <img
                    className="logo"
                    src="/images/logo_full.png"
                    alt="logo"
                  />
                </Link>
              </li>
              <li className="header-links">
                <p>
                  Already have an account? {"   "}
                  <Link to="/login" className="bold">
                    Sign In
                  </Link>
                </p>
              </li>
            </ul>
            <StepCircleComponent step={step} />

            <RegisterSteps
              hasError={hasError}
              errMsg={errMsg}
              hasBackendError={hasBackendError}
              backendErrorMsg={backendErrorMsg}
              step={step}
              handleInputChange={this.handleInputChange}
              goToStepTwo={this.goToStepTwo}
              goToStepOne={this.goToStepOne}
              goToStepThree={this.goToStepThree}
            />
          </div>
          <div className="col-lg-1 col-md-0"></div>
        </div>
      </section>
    );
  }
}

export default RegisterComponent;
