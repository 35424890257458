import React from "react";
import Select from "react-select";
const qualitiessList = [
  { value: "Excellent", label: "Excellent" },
  { value: "Good", label: " Good " },
  { value: "Fair", label: " Fair " },
  { value: "Poor", label: " Poor " },
];
const QualitiessList = (props) => {
  let { selectQuality } = props;
  const change = ({ value }) => {
    selectQuality({ target: { name: "quality", value } });
  };
  return (
    <>
      <Select
        options={qualitiessList}
        onChange={change}
        classNamePrefix="customSelect"
        className="quality-list "
        isSearchable={false}
      />
    </>
  );
};

export default QualitiessList;
