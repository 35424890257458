import React from "react";
import ContentLoader from "react-content-loader";

const _ExamLoaderComponent = () => (
  <ContentLoader speed={2} width={1000} height={360} viewBox="0 0 600 360">
    <rect x="20" y="67" rx="0" ry="0" width="366" height="285" />
    <rect x="17" y="13" rx="0" ry="0" width="324" height="12" />
    <rect x="432" y="71" rx="0" ry="0" width="142" height="24" />
    <rect x="271" y="76" rx="0" ry="0" width="18" height="0" />
    <rect x="258" y="120" rx="0" ry="0" width="52" height="1" />
    <rect x="266" y="86" rx="0" ry="0" width="57" height="8" />
    <rect x="490" y="115" rx="0" ry="0" width="68" height="2" />
    <rect x="492" y="116" rx="0" ry="0" width="73" height="8" />
    <rect x="496" y="135" rx="0" ry="0" width="38" height="0" />
    <rect x="502" y="135" rx="0" ry="0" width="39" height="0" />
    <rect x="495" y="135" rx="0" ry="0" width="71" height="11" />
    <rect x="449" y="191" rx="0" ry="0" width="124" height="29" />
  </ContentLoader>
);

export default _ExamLoaderComponent;
