import React from "react";
import store2 from "store2";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "~/Store/Apis/BaseUrl";
// Components
import ProfileUserInfoComponent from "./_InfoComponent";
import SidebarComponent from "~/Components/Sidebar";

// Actions
import "~/styles/Admin/Doctors/_DoctorPage.scss";

const JWT_TOKEN = store2.get("token");

class ProfileComponent extends React.Component {
  state = {
    loading: true,
    hasError: false,
    errMsg: [],
    inputDisabled: true,
    form: {
      firstName: "",
      lastName: "",
      country: "",
      email: "",
      institution: "",
      specialization: "",
      title: "",
      experince: "",
    },
  };
  constructor(props) {
    super(props);
    this.doctorId = props.match.params.doctorId;
  }

  componentDidMount() {
    this.getDrInfo();
  }

  getDrInfo = () => {
    axios
      .get(`${baseUrl}/auth/profile`, {
        headers: {
          token: JWT_TOKEN,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          const user = res.data.user;
          store2.set("user", user);
          console.log("user data", user);
          this.setState({
            loading: false,
            form: user,
            training: user.answers,
          });
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "Server Error ",
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  handleInputChange = (event) => {
    const form = { ...this.state.form };
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  editProfile = (e) => {
    e.preventDefault();
    this.setState({
      inputDisabled: false,
    });
  };
  updateProfile = (e) => {
    e.preventDefault();
    axios
      .put(`${baseUrl}/auth/profile`, this.state.form, {
        headers: {
          token: JWT_TOKEN,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          store2.set("token", res.data._token);
          window.location.reload();
          console.log(res.data);
        } else {
          this.setState({
            loading: false,
            hasError: true,
            error_msg: "Server Error ",
          });
        }
      })
      .catch((err) => {
        return err;
      });
    // redirect to exam
  };
  render() {
    let { loading, hasError, form, inputDisabled } = this.state;
    return (
      <div className="profile">
        <SidebarComponent type="dr" activeItem="profile" />

        <section className="page">
          <div className="row top-bar">
            <div className="col-md-1 mt-auto" />
            <div className="col-md-7 ">
              <h3>
                <Link
                  to="#"
                  className="no-style"
                  onClick={() => this.props.history.goBack()}
                >
                  <i class="fas fa-caret-left"></i> Back to Dashboard{" "}
                </Link>
              </h3>
            </div>
            <div className="col-md-3">
              {inputDisabled && (
                <h3 className="edit-profile" onClick={this.editProfile}>
                  {" "}
                  Edit Profile
                </h3>
              )}
            </div>
          </div>
          <div className="doctors">
            <div className="row page-title">
              <div className="col-md-1" />
              <div className="col-md-3">
                <div className="header">
                  <h1>Profile </h1>
                </div>
              </div>
            </div>

            <div className="row user-info">
              <div className="col-md-1" />
              <div className="col-md-8">
                {loading && <h1> Loading..</h1>}

                {!loading && !hasError && (
                  <ProfileUserInfoComponent
                    inputDisabled={inputDisabled}
                    user={form}
                    editProfile={this.editProfile}
                    updateProfile={this.updateProfile}
                    handleInputChange={this.handleInputChange}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default ProfileComponent;
