import React from "react";
import Chart from "react-google-charts";

const BarChartComponent = (props) => {
  const { title, value, options, data } = props;
  return (
    <div className="bar-chart-box">
      <p>{title}</p>
      <span> {value} </span>
      {/* Google Chart */}
      <Chart
        chartType="ColumnChart"
        options={options}
        width="100%"
        height="200px"
        data={data}
      />
    </div>
  );
};

export default BarChartComponent;
