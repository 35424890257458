import React from "react";
import humanize from "humanize-duration";

export const AnswerTimeInfo = (props) => {
  let { currentAnswer } = props;
  return (
    <ul className="time-info">
      <li>
        Time:{" "}
        <span>
          {humanize(currentAnswer.duration * 1000, {
            units: ["m"],
            round: true,
          })}{" "}
        </span>
      </li>
      <li>
        Time Active :{" "}
        <span>
          {humanize((currentAnswer.duration - currentAnswer.timeIdle) * 1000, {
            units: ["m"],
            round: true,
          })}{" "}
        </span>
      </li>
      <li>
        Time Idle:{" "}
        <span>
          {humanize(currentAnswer.timeIdle * 1000, {
            units: ["s"],
            round: true,
          })}{" "}
        </span>
      </li>
    </ul>
  );
};
