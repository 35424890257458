import React from "react";
import Select from "react-select";
import Pagination from "react-js-pagination";

const sortOptions = [
  { value: "5", label: "5 per page" },
  { value: "10", label: "10 per page" },
  { value: "15", label: " 15 per page" },
];

const TableHeaderActions = (props) => {
  let { loading, updateLimit, paginate, page, limit, totalDocs } = props;
  console.log("V is", page, limit, totalDocs);
  const change = ({ value }) => {
    updateLimit(value);
  };
  return (
    <div className="row table-footer">
      <div className="col-md-1" />
      <div className="col-md-5">
        <div
          style={{ display: "flex" }}
          className={`${loading ? "hide-this" : ""}`}
        >
          <label className="sort-by-label">View </label>
          <Select
            options={sortOptions}
            onChange={change}
            classNamePrefix="customSelect"
            placeholder="Per Page"
          />
        </div>
      </div>
      <div className="col-md-5">
        <Pagination
          prevPageText="prev"
          nextPageText="next"
          activePage={page}
          itemsCountPerPage={limit}
          totalItemsCount={totalDocs}
          pageRangeDisplayed={5}
          onChange={paginate}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
};
export default TableHeaderActions;
